import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import HeaderComponent from "components/Header/Header"
import TeamList from "components/TeamList/TeamList"
import TitlePage from "components/TitlePage/TitlePage"

import { teamStore } from "store/Team/TeamStore"

const TeamPage = () => {
	useEffect(() => {
		teamStore.getAllTeam()
	}, [])
	return (
		<>
			<SEO title="Наша команда" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Наша команда"
					buttonText="Добавить члена команды"
					buttonLink="/team/0"
					fileName="team"
				/>
				<TeamList dataSource={teamStore.listTeam} items={teamStore.total} />
			</div>
		</>
	)
}

export default observer(TeamPage)
