import { DeleteOutlined, EditOutlined, FileTextOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Table } from "antd"
import { observer } from "mobx-react-lite"
import { CSVDownload } from "react-csv"
import { Link, NavLink } from "react-router-dom"

export const PromocodeList = observer(({ items, count, store }) => {
	const regexp = /%/
	const CSV = (
		<CSVDownload
			data={store.dataExport}
			filename={"promocode.csv"}
			separator={";"}
			enclosingCharacter={``}
			uFEFF={true}
		/>
	)
	const columns = [
		{
			title: "Наименование/ID",
			dataIndex: "name",
			render: (_, item) => {
				return (
					<Link to={`/promocode/${item.id}`}>
						(ID:{item.id}) {item.name}
					</Link>
				)
			},
		},
		{
			title: "Промокод",
			dataIndex: "promocode",
		},
		{
			title: "Дата создания",
			dataIndex: "dateCreate",
		},
		{
			title: "Активность",
			dataIndex: "activity",
		},
		{
			title: "Продукт",
			dataIndex: "course",
			render: (_, list) => {
				return list.courses.map((item) => {
					return (
						<div key={item.id}>
							<Link to={`/courses/${item.id}`}>
								(ID: {item.id}) {item.title}
							</Link>
						</div>
					)
				})
			},
		},
		{
			title: "Статус",
			dataIndex: "status",
			render: (_, item) => {
				return item.status ? <>Активен</> : <>Отключен</>
			},
		},
		{
			title: "Использован",
			dataIndex: "totalUses",
			render: (_, item) => {
				return <Link to={`/promocode/payment/${item.id}`}>Количество: {item.totalUses}</Link>
			},
		},
		{
			title: "Скидка",
			dataIndex: "discount",
			render: (_, item) => {
				return regexp.test(item.discount)
					? item.discount
					: new Intl.NumberFormat("ru-RU", {
							style: "currency",
							notation: "standard",
							maximumFractionDigits: 0,
							currency: "RUB",
					  }).format(item.discount)
			},
		},
		{
			title: "Тип",
			dataIndex: "type",
		},
		{
			title: "Действия",
			dataIndex: "operation",
			width: 170,
			render: (_, item) => {
				return (
					<>
						<Button type="link">
							<NavLink to={`/promocode/${item.id}`}>
								<EditOutlined />
							</NavLink>
						</Button>

						<Button type="link" onClick={(event) => store.exportPaymentsPromocode(event, item.id)}>
							{store.statusCSV ? CSV : null}

							<FileTextOutlined />
						</Button>

						<Popconfirm
							title="Удалить запись?"
							onConfirm={() => store.deletePromocode(item.id)}
							okText="Да"
							cancelText="Нет"
						>
							<Button type="link">
								<DeleteOutlined />
							</Button>
						</Popconfirm>
					</>
				)
			},
		},
	]
	return (
		<>
			<Table dataSource={items} columns={columns} pagination={store.pagination} />
			<div>Общее кол-во записей: {count}</div>
		</>
	)
})
