import { message } from "antd"
import FormData from "form-data"
import { action, makeAutoObservable } from "mobx"

import dateFormat from "hooks/dateFormat"

import axios from "../../axios"
import config from "../../config.json"

class TeamStore {
	constructor() {
		makeAutoObservable(this)
	}

	listTeam = []
	team = {}
	total = ""
	limit = 15
	offset = 0
	pageSize = 15
	listFull = []

	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			this.offset = this.getAllTeam(this.newItems, offset)
		},
	}

	onChange(name, value) {
		this.team[name] = value
	}

	getAllTeam(limit = this.limit, offset = this.offset) {
		axios
			.get("team/members", {
				params: {
					limit: limit,
					offset: offset,
				},
			})
			.then(
				action((success) => {
					const [teams, count] = success.data
					this.total = count[0].count
					this.pagination.total = this.total

					this.listTeam = teams.map((team) => {
						return {
							key: team.id,
							...team,
							date_create: team?.date_create ? dateFormat(team?.date_create) : null,
							date_update: team?.date_update ? dateFormat(team?.date_update) : null,
						}
					})
				}),
			)
	}

	getFullTeam() {
		axios
			.get("team/members", {
				params: {
					limit: this.total,
				},
			})
			.then(
				action((success) => {
					const [teams] = success.data

					this.listFull = teams.map((team) => {
						return {
							key: team.id,
							...team,
							date_create: team?.date_create ? dateFormat(team?.date_create) : null,
							date_update: team?.date_update ? dateFormat(team?.date_update) : null,
						}
					})
				}),
			)
	}

	getTeam(id) {
		axios
			.get(`team/member/${id}`)
			.then(
				action((success) => {
					this.team = success.data
					if (this.team.photo !== null && success.data.photo !== "") {
						this.team.photo = [
							{
								status: "done",
								url: config.apiUrl + "" + success.data.photo,
							},
						]
					} else {
						this.team.photo = []
					}
				}),
			)
			.catch((error) => {
				if (error.request.statue === 400) {
					window.location.href = "/"
				}
			})
	}

	setTeam() {
		this.team = {
			id: 0,
			status: 0,
			name: "",
			photo: null,
			post: "",
			descrition: "",
			position: "",
			contact: "",
			user_id: "",
		}
	}

	editTeam(e) {
		e.preventDefault()
		let team = new FormData()
		let url

		if (this.team.id > 0) {
			url = `edit/${this.team.id}`
		} else {
			url = "new"
		}

		if (this.team.photo !== null) {
			if (this.team.photo[0] && this.team.photo[0].name) {
				team.append("photo", this.team.photo[0])
			} else if (!this.team.photo[0]) {
				team.append("photo", [])
			}
		}

		if (!this.team.name) {
			return message.error("Не заполнено название")
		}

		team.append("status", Number(this.team.status))
		team.append("name", this.team.name)
		team.append("post", this.team.post)
		team.append("contact", this.team.contact)
		team.append("description", this.team.description)
		team.append("position", this.team.position)
		team.append("user_id", this.team.user_id)

		axios
			.post(`team/${url}`, team)
			.then(
				action((success) => {
					message.success("Успешно")
					document.location.href = "/team"
				}),
			)
			.catch((error) => {
				message.error("Ошибка")
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	deleteTeam(key) {
		axios
			.delete(`team/member/${key}`)
			.then(
				action((success) => {
					message.success("Успешно")
					this.getAllTeam(this.limit, this.offset)
				}),
			)
			.catch((error) => {
				if (error.request.statue === 403) {
					message.error("Ошибка удаления")
				}
			})
	}
}

export const teamStore = new TeamStore()
