import { Table } from "antd"
import { Link } from "react-router-dom"

import dateFormat from "hooks/dateFormat"

export const Payment = ({ data }) => {
	const regexp = /%/
	const columns = [
		{ title: "ID транзакции", dataIndex: "extId" },
		{ title: "ID оплаты", dataIndex: "id" },
		{ title: "Плательщик", dataIndex: "fio" },
		{
			title: "Курс",
			dataIndex: "course",
			render: (_, item) => {
				return (
					<div key={item.course.id}>
						<Link to={`/courses/${item.course.id}`}>
							(ID: {item.id}) {item.course.title}
						</Link>
					</div>
				)
			},
		},
		{
			title: "Стоимость",
			dataIndex: "amount",
			render: (_, item) => {
				return new Intl.NumberFormat("ru-RU", {
					style: "currency",
					notation: "standard",
					maximumFractionDigits: 0,
					currency: "RUB",
				}).format(item.amount)
			},
		},
		{
			title: "Скидка",
			dataIndex: "discount",
			render: (_, item) => {
				return regexp.test(item.discount)
					? item.discount
					: new Intl.NumberFormat("ru-RU", {
							style: "currency",
							notation: "standard",
							maximumFractionDigits: 0,
							currency: "RUB",
					  }).format(item.discount)
			},
		},
		{
			title: "Дата оплаты",
			dataIndex: "datePayed",
			render: (_, item) => dateFormat(item.datePayed, "DD.MM.YYYY"),
		},
		{ title: "Способ оплаты", dataIndex: "system", render: (_, item) => item.system.name },
	]

	return (
		<>
			<Table dataSource={data} columns={columns} pagination={false} />
			<div>Общее кол-во записей: {data.length}</div>
		</>
	)
}
