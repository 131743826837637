import { message } from "antd"
import { action, makeAutoObservable } from "mobx"
import moment from "moment"
import "moment/locale/ru"

import dateFormat from "hooks/dateFormat"

import axios from "../../axios"
import config from "../../config.json"

class WebinarsStore {
	listWebinars = []
	webinarListOnUserRegistered = []
	webinarReg = []
	webinar = {}
	total = 0
	totalReg = 0
	limit = 15
	offset = 0
	pageSize = 15
	type = ""
	idWebinarsRegistration = ""
	listFullWebinar = []
	listFullwebinarReg = []
	sort = {
		sort_id: 1,
		status: 0,
	}

	filter = {
		status: 0,
		sortId: 0, // false - ASC, true - DESC
		purchased_courses: [], // Array id courses
		filter: {
			phone: "",
			email: "",
		},
	}
	statusCSV = false

	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			if (this.sort.status === 0) {
				this.offset = this.getAllWebinars(this.newItems, offset)
			} else {
				this.offset = this.getSortWebinars(this.newItems, offset)
			}
		},
	}

	paginationReg = {
		pageSize: this.pageSize,
		total: this.totalReg,
		onChange: (current) => {
			this.type = "regPagination"
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			this.offset = this.getAllWebinars(this.newItems, offset)
		},
	}

	constructor() {
		makeAutoObservable(this)
	}
	onChange(name, value) {
		this.webinar[name] = value
	}

	onChangeDate(name, value) {
		this.webinar[name] = Math.trunc(moment(value) / 1000)
	}

	onChangeFilter = (name, value) => {
		this.filter[name] = value
	}

	onChangeSort = (value) => {
		this.sort.sort_id = value
	}

	onChangeFilterInput(name, value) {
		this.filter.filter[name] = value
	}

	getSortWebinars(limit = this.limit, offset = this.offset) {
		axios
			.get("/webinars/webinars", {
				params: {
					limit: limit,
					offset: offset,
					sort_id: this?.sort?.sort_id,
				},
			})
			.then(
				action((success) => {
					const [webinarReg] = success.data
					this.listWebinars = webinarReg.map((webinar, index) => {
						return {
							key: index + 1,
							...webinar,
							date_reg_on_webinar: webinar?.date_reg_on_webinar
								? dateFormat(webinar?.date_reg_on_webinar)
								: null,
						}
					})
				}),
			)
	}

	getAllWebinars(limit = this.limit, offset = this.offset) {
		axios
			.get("/webinars/webinars", {
				params: {
					limit: limit,
					offset: offset,
					sort_id: this?.sort?.sort_id,
				},
			})
			.then(
				action((success) => {
					const [webinars, count] = success.data
					this.total = count[0].count
					this.pagination.total = this.total
					this.listWebinars = webinars.map((webinar, index) => {
						return {
							key: webinar.id,
							...webinar,
							date_create: webinar?.date_create ? dateFormat(webinar?.date_create) : null,
							date_start: webinar?.date_start ? dateFormat(webinar?.date_start) : null,
							date_update: webinar?.date_update ? dateFormat(webinar?.date_update) : null,
						}
					})
				}),
			)
	}

	getListWebinars = () => {
		axios
			.get("/webinars/webinars", {
				params: {
					limit: this.total,
					sort_id: this?.sort?.sort_id,
				},
			})
			.then(
				action((success) => {
					const [webinars] = success.data
					this.listFullWebinar = webinars.map((webinar, index) => {
						return {
							key: webinar.id,
							...webinar,
							date_create: webinar?.date_create ? dateFormat(webinar?.date_create) : null,
							date_start: webinar?.date_start ? dateFormat(webinar?.date_start) : null,
							date_update: webinar?.date_update ? dateFormat(webinar?.date_update) : null,
						}
					})
					this.statusCSV = true
					setTimeout(
						action(() => {
							this.statusCSV = false
						}),
						1000,
					)
				}),
			)
	}

	getWebinar(id) {
		axios.get(`/webinars/webinar/${id}`).then(
			action((success) => {
				this.webinar = success.data
				if (this.webinar.photo !== null && success.data.photo !== "") {
					this.webinar.photo = [
						{
							status: "done",
							url: config.apiUrl + "" + success.data.photo,
						},
					]
				} else {
					this.webinar.photo = []
				}
			}),
		)
	}

	setWebinar() {
		this.webinar = {
			id: 0,
			name: "",
			course: "",
			photo: null,
			status: 0,
			webinar_stream: "",
			show_prices: 0,
			date_start: "",
			time_start: "",
		}
	}

	editWebinar(e) {
		e.preventDefault()
		let webinar = new FormData()
		let url

		if (this.webinar.id > 0) {
			url = `edit/${this.webinar.id}`
		} else {
			url = "new"
		}

		if (!this.webinar.name) return message.error("Не заполнено название")
		if (!this.webinar.course) return message.error("Не выбран курс")

		if (this.webinar.photo !== null) {
			if (this.webinar.photo[0] && this.webinar.photo[0].name) {
				webinar.append("photo", this.webinar.photo[0])
			} else if (!this.webinar.photo[0]) {
				webinar.append("photo", [])
			}
		}

		webinar.append("name", this.webinar.name)
		webinar.append("course", this.webinar.course)
		webinar.append("status", this.webinar.status)
		webinar.append("webinar_stream", this.webinar.webinar_stream)
		webinar.append("show_prices", this.webinar.show_prices)
		webinar.append("date_start", this.webinar.date_start)
		webinar.append("time_start", this.webinar.time_start)

		axios
			.post(`/webinars/${url}`, webinar)
			.then(
				action((success) => {
					message.success("Успешно")
					document.location.href = "/webinars"
				}),
			)
			.catch((error) => {
				message.error("Ошибка")
			})
	}

	deleteWebinar(key) {
		axios.delete(`/webinars/webinar/${key}`).then(
			action((success) => {
				if (success.data === 1) {
					message.success("Успешно")
					this.getAllWebinars(this.limit, this.offset)
				} else {
					message.error("Ошибка")
				}
			}),
		)
	}

	getWebinarsRegistration(limit = this.limit, offset = this.offset, id) {
		this.webinarReg = []
		this.totalReg = 0
		axios
			.get(`/webinars/regs/${id}`, {
				params: {
					limit: limit,
					offset: offset,
				},
			})
			.then(
				action((success) => {
					const [webinarReg, count] = success.data
					this.totalReg = count[0].count
					this.pagination.total = this.totalReg
					this.webinarReg = webinarReg.map((webinar, index) => {
						return {
							key: index + 1,
							...webinar,
							date_reg_on_webinar: webinar?.date_reg_on_webinar
								? dateFormat(webinar?.date_reg_on_webinar)
								: null,
						}
					})
				}),
			)
	}

	getFiltersWebinarsRegistration(id, limit = this.limit, offset = this.offset) {
		const filterList = []
		if (this?.filter?.filter?.phone) {
			filterList?.push({
				name: "phone",
				value: this?.filter?.filter?.phone,
			})
		}

		if (this?.filter?.filter?.email) {
			filterList?.push({
				name: "email",
				value: this?.filter?.filter?.email,
			})
		}
		const params = {
			limit: limit,
			offset: offset,
			filter: JSON.stringify(filterList),
			purchased_courses: JSON.stringify(this.filter.purchased_courses),
			sort_id: this.filter.sortId,
		}

		axios
			.get(`/webinars/regs/${id}`, {
				params,
			})
			.then(
				action((success) => {
					this.filter.status = 1
					const [webinarReg, count] = success.data
					this.total = count[0].count
					this.pagination.total = this.total
					this.webinarReg = webinarReg.map((webinar, index) => {
						return {
							key: index + 1,
							...webinar,
							date_reg_on_webinar: webinar?.date_reg_on_webinar
								? dateFormat(webinar?.date_reg_on_webinar)
								: null,
						}
					})
				}),
			)
	}

	getFullWebinarsRegistration(id) {
		axios
			.get(`/webinars/regs/${id}`, {
				params: {
					limit: this.total,
				},
			})
			.then(
				action((success) => {
					const [webinarReg] = success.data
					this.webinarRegFull = webinarReg.map((webinar, index) => {
						return {
							key: index + 1,
							...webinar,
							date_reg_on_webinar: webinar?.date_reg_on_webinar
								? dateFormat(webinar?.date_reg_on_webinar)
								: null,
						}
					})
				}),
			)
	}

	getListRegWebinars = (id) => {
		const filterList = []
		if (this?.filter?.filter?.phone) {
			filterList?.push({
				name: "phone",
				value: this?.filter?.filter?.phone,
			})
		}

		if (this?.filter?.filter?.email) {
			filterList?.push({
				name: "email",
				value: this?.filter?.filter?.email,
			})
		}
		const params = {
			limit: this.total,
			filter: JSON.stringify(filterList),
			purchased_courses: JSON.stringify(this.filter.purchased_courses),
			sort_id: this.filter.sortId,
		}
		axios
			.get(`/webinars/regs/${id}`, {
				params,
			})
			.then(
				action((success) => {
					const [webinars] = success.data
					this.listFullwebinarReg = webinars.map((webinar, index) => {
						return {
							key: index,
							...webinar,
							date_create: webinar?.date_create ? dateFormat(webinar?.date_create) : null,
							date_start: webinar?.date_start ? dateFormat(webinar?.date_start) : null,
							date_update: webinar?.date_update ? dateFormat(webinar?.date_update) : null,
						}
					})
					this.statusCSV = true
					setTimeout(
						action(() => {
							this.statusCSV = false
						}),
						1000,
					)
				}),
			)
	}
	async getAllWebinarsOnUser(userId) {
		try {
			const { data } = await axios.get(`/webinars/user/${userId}`)
			this.webinarListOnUserRegistered = data.data.items.map(({ id, name, course, date_registration }) => ({
				key: id,
				name,
				course: course.title,
				date_registration: dateFormat(date_registration),
			}))
		} catch (error) {
			console.error(error)
		}
	}

	setDate(d) {
		const dateFormat = "YYYY-MM-DD HH:mm"
		d = moment.unix(d).format(dateFormat)
		return moment(d, dateFormat)
	}
}

export const webinarsStore = new WebinarsStore()
