import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import HeaderComponent from "components/Header/Header"
import { Form, PromocodeList } from "components/Promocode"
import TitlePage from "components/TitlePage/TitlePage"

import { coursesStore } from "store/Courses/CoursesStore"
import { store } from "store/Promocode/Promocode.store"

import styles from "./PromoCodePage.module.scss"

const PromoCodePage = () => {
	useEffect(() => {
		store.getListPromocodes()
		coursesStore.getAllCourses(0)
	}, [])

	if (!store.list.success) {
		return null
	}

	return (
		<>
			<SEO title="Список промокодов" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Cписок промокодов"
					buttonText="Добавить промокод"
					buttonLink="/promocode/0"
					fileName="promocode"
					// getList={store.getList}
					// store={store}
				/>

				<Form store={store} listCourses={coursesStore.listCourses} />
				<PromocodeList items={store.list.items} count={store.list.count} store={store} />
			</div>
		</>
	)
}

export default observer(PromoCodePage)
