import { FileSyncOutlined, FileTextOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, Row, Typography, message, notification } from "antd"
import { observer } from "mobx-react-lite"
import { CSVDownload } from "react-csv"
import { useNavigate } from "react-router-dom"

import "./TitlePage.scss"

const { Title } = Typography
const TitlePage = (props) => {
	const [messageApi, contextHolder] = message.useMessage()
	const navigate = useNavigate()
	const {
		status = true,
		title = "Заголовок",
		buttonText = "Добавить",
		buttonLink = "",
		buttonModal = "",
		fileName = "file",
		listFull = null,
		getList = null,
		store,
		paramsId = null,
		isAviableUpdatePaymentReceipt = false,
	} = props

	const buttonClick = () => {
		if (buttonLink !== "" && buttonModal !== "") {
			return message.error("Оставьте только одно действие с кнопкой!")
		} else if (buttonLink !== "") {
			return navigate(buttonLink)
		} else if (buttonModal !== "") {
			return buttonModal
		} else {
			return notification.error({
				message: "Ошибка компонента <TitlePage />!",
				description:
					'Отсутствует 1 из 2 параметров компонента!\nДобавьте параметр "buttonLink" или "buttonModal".',
				placement: "top",
			})
		}
	}

	return (
		<Row gutter={[15, 24]} justify="space-between" className="TitlePage">
			<Col xs={24} md={18} className="TitlePage-title">
				{status === true ? <Title level={2}>{title}</Title> : ""}
			</Col>

			<Col xs={24} md={6} className="TitlePage-button">
				{buttonLink !== "" && (
					<Button onClick={buttonLink ? buttonClick : buttonClick()} icon={<PlusOutlined />}>
						{buttonText}
					</Button>
				)}
				{buttonModal !== "" && (
					<Button onClick={buttonLink ? buttonClick : buttonClick()} icon={<PlusOutlined />}>
						{buttonText}
					</Button>
				)}
				{isAviableUpdatePaymentReceipt && (
					<>
						{contextHolder}
						<Button
							onClick={async () => {
								messageApi.open({
									key: "updateCheck",
									type: "lodaing",
									content: "Запрос выполняется",
								})
								const { result } = await store.actualizeChecks()
								if (result.status >= 200 && result.status < 300) {
									messageApi.open({
										key: "updateCheck",
										type: "success",
										content: "запрос выполнен",
									})
								} else {
									messageApi.open({
										key: "updateCheck",
										type: "error",
										content: "Произошла ошибка",
										duration: 2.5,
									})
								}
							}}
						>
							<FileSyncOutlined />
						</Button>
					</>
				)}
				{store || getList !== null ? (
					<Button
						className="TitlePage-CSV"
						onClick={(e) => {
							e.preventDefault()
							getList === null ? store.getList() : paramsId ? getList(paramsId) : getList()
						}}
					>
						{store.statusCSV ? (
							<CSVDownload
								data={listFull === null ? store.listFull : listFull}
								filename={`${fileName}.csv`}
								separator={";"}
								enclosingCharacter={``}
								uFEFF={true}
							/>
						) : null}

						<FileTextOutlined />
					</Button>
				) : null}
			</Col>
		</Row>
	)
}

export default observer(TitlePage)
