import { Button, Col, DatePicker, Input, InputNumber, Row, Select, Space, Switch, Typography } from "antd"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import dateFormat from "hooks/dateFormat"

import { Discount } from "../"

const { Title } = Typography
const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select
export const Form = observer(({ params, store, listCourses }) => {
	const navigate = useNavigate()
	const onSubmit = (event) => {
		event.preventDefault()
		if (Number(params.id) === 0) {
			return store.postCreatePromocode()
		}

		if (Number(params.id) > 0) {
			return store.patchCreatePromocode(Number(params.id))
		}
	}
	const onReset = (event) => {
		event.preventDefault()
		return navigate("/promocode")
	}

	if (Number(params.id) > 0 && store.form.date.start === "" && store.form.date.end === "") {
		return null
	}

	const startAction = moment.unix(store.form.date.start)
	const endAction = moment.unix(store.form.date.end)

	return (
		<form onSubmit={(event) => onSubmit(event)} onReset={(event) => onReset(event)}>
			<Row className="CreateUsers__row" gutter={[15, 24]}>
				<Col xs={24} lg={18} className="CreateUsers__title">
					<Row gutter={[15, 24]} justify="space-between">
						<Col xs={24} md={20}>
							<Title>{params.id > 0 ? "Редактирование промокод" : "Новый промокод"}</Title>
						</Col>

						<Col xs={24} md={4} className="CreateUsers__title-block">
							<Space size="small">
								<Button type="primary" size="large" htmlType="submit">
									{params.id > 0 ? "Сохранить" : "Создать"}
								</Button>

								<Button type="link" size="large" htmlType="reset">
									Отменить
								</Button>
							</Space>
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={18}>
					<Row gutter={[15, 24]}>
						{/* // Begin - Строка */}
						<Col xs={24} lg={16}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Название</Title>
								<Input
									size="large"
									placeholder="Введите название"
									value={store.form.name}
									onChange={(event) => store.onChange("name", event.target.value)}
									required
								/>
							</Space>
						</Col>

						<Col xs={24} lg={4}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Тип промокода</Title>
								<Select
									style={{ width: "100%" }}
									size="large"
									defaultValue={0}
									value={store.form.promocode.type}
									onChange={(event) => store.onChangePromocode("type", event)}
									options={[
										{
											value: 0,
											label: "Многоразовый",
										},
										{
											value: 1,
											label: "Одноразовый",
										},
									]}
								/>
							</Space>
						</Col>

						<Col xs={24} lg={4}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Статус промокода</Title>
								<Switch
									checkedChildren="Активено"
									unCheckedChildren="Отключено"
									checked={store.form.checked}
									defaultChecked={true}
									onChange={store.onSwitch}
								/>
							</Space>
						</Col>
						{/* // End - Строка */}

						{/* // Begin - Строка */}
						<Col xs={24} lg={6}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Промокод</Title>
								<Input
									size="large"
									placeholder="Введите промокод"
									value={store.form.promocode.name}
									onChange={(event) => store.onChangePromocode("name", event.target.value)}
									required
								/>
							</Space>
						</Col>

						<Col xs={24} lg={6}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Размер скидки</Title>
								<Discount
									valueInputInterest={store.form.discount.amount.interest}
									onChangeInputInterest={(event) => store.onChangeDiscountInput(event, "interest")}
									valueInputPrice={store.form.discount.amount.price}
									onChangeInputPrice={(event) => store.onChangeDiscountInput(event, "price")}
									valueSelect={store.form.discount.type}
									onChangeSelect={store.onChangeDiscountSelect}
									required
								/>
							</Space>
						</Col>

						<Col xs={24} lg={8}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Срок действия</Title>

								<RangePicker
									style={{ width: "100%" }}
									size="large"
									showTime={{
										format: "HH:mm",
									}}
									defaultPickerValue={
										store.form.date.start !== "" &&
										store.form.date.end !== "" && [startAction, endAction]
									}
									defaultValue={
										store.form.date.start !== "" &&
										store.form.date.end !== "" && [startAction, endAction]
									}
									locale="ru-RU"
									format="DD.MM.YYYY HH:mm"
									onChange={(event) => {
										store.onChangeDate(event)
									}}
									required
								/>
							</Space>
						</Col>

						{/* // End - Строка */}

						{/* // Begin - Строка */}
						<Col xs={24} lg={12}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Курс</Title>
								<Select
									style={{ width: "100%" }}
									size="large"
									mode="multiple"
									allowClear
									placeholder="Выберите курсы"
									value={store.form.courses}
									onChange={(event) => store.onChange("courses", event)}
									required
								>
									{listCourses.map((item) => (
										<Option key={item.id} value={item.id}>
											(ID: {item.id}) {item.title}
											<br />
											{item?.city && item?.city !== "null" ? <>({item?.city})</> : null}
										</Option>
									))}
								</Select>
							</Space>
						</Col>

						<Col xs={24} lg={12}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<Title level={4}>Описание промокода</Title>
								<TextArea
									style={{ width: "100%" }}
									value={store.form.description}
									onChange={(event) => store.onChange("description", event.target.value)}
									placeholder="Введите описание промокода"
									autoSize={{
										minRows: 3,
										maxRows: 6,
									}}
								/>
							</Space>
						</Col>
						{/* // End - Строка */}
					</Row>
				</Col>
			</Row>
		</form>
	)
})
