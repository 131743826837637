// Import Framework
import { Table, Typography } from "antd"
// Import Style
// Import Store
import { observer } from "mobx-react-lite"
import { NavLink } from "react-router-dom"

import { AccessCourse } from "./AccessCourse"

// Import Components
import CuratorCourse from "../CuratorCourse"

// Import Hooks

const PaymentsCourses = ({ userList, courseId, store }) => {
	console.log(userList)
	const columns = [
		{
			title: "Ученик",
			dataIndex: "payer_id",
			render: (_, user) => {
				if (user) {
					return (
						<NavLink to={`/users/${user.id}`}>
							{user.name} {user.surname}
							<br />
							ID: {user.id}
						</NavLink>
					)
				} else {
					return <>id: {user?.payer_id}</>
				}
			},
		},
		{
			title: "Куратор",
			dataIndex: "curator",
			render: (_, user) => {
				return <CuratorCourse courseId={courseId} userId={Number(user?.payer_id)} />
			},
		},
		{
			title: "Номер телефона",
			dataIndex: "phone",
			render: (_, user) => {
				return user?.phone
			},
		},
		{
			title: "Статус пользователя",
			dataIndex: "status",
			render: (_, { id, isActive }) => {
				return <AccessCourse access={isActive} courseId={Number(courseId)} userId={Number(id)} store={store} />
			},
		},
		{
			title: "Последний урок",
			dataIndex: "lastLesson",
			render: (_, { lastLesson }) => {
				return lastLesson?.title
			},
		},
	]

	return (
		<>
			<Table dataSource={userList} columns={columns} pagination={false} />
			<div className="tableCountItems">Всего записей: {userList.length}</div>
		</>
	)
}

export default observer(PaymentsCourses)
