import { Button, Col, DatePicker, Input, Row, Select, Space, Table, Typography } from "antd"
import locale from "antd/es/date-picker/locale/ru_RU"
import { observer } from "mobx-react-lite"
import "moment/locale/ru"
import { Link, NavLink } from "react-router-dom"

import GetPaymentType from "components/Payments/GetPaymentType"

import { paymentStore } from "store/PaymentStore/PaymentStore"

import dateFormat from "hooks/dateFormat"

const { Option } = Select
const { RangePicker } = DatePicker
const PaymentsList = (props) => {
	const payerColumn = {
		title: "Плательщик",
		dataIndex: "payer_id",
		render: (_, payment) => {
			if (payment?.payer) {
				return (
					<NavLink to={`/users/${payment.payer?.id}`}>
						{payment.payer?.name} {payment.payer?.surname}
						<br />
						ID: {payment.payer?.id}
					</NavLink>
				)
			} else {
				return <>id: {payment?.payer_id}</>
			}
		},
	}

	const columns = [
		{ title: "ID", dataIndex: "id" },
		{
			title: "Курс",
			dataIndex: "course_id",
			render: (_, payment) => {
				const course = payment?.course
				if (course?.title)
					return (
						<Link to={`/courses/${course?.id}`}>
							(ID: {course?.id}) {course.title}
							<br />
							{course?.city && course?.city !== "null" ? course?.city : null}
							{course?.date_start && course?.date_start !== "null" ? (
								<>
									<br />
									старт {dateFormat(course?.date_start, "DD.MM.YYYY")}
								</>
							) : null}
						</Link>
					)
			},
		},
		{
			title: "Создана",
			dataIndex: "date_create",
		},
		{
			title: "Оплачена",
			dataIndex: "date_payed",
		},
		{
			title: "Сумма",
			dataIndex: "amount",
			render: (_, record) => {
				return new Intl.NumberFormat("ru-RU", {
					style: "currency",
					notation: "standard",
					maximumFractionDigits: 0,
					currency: "RUB",
				}).format(record.amount)
			},
		},
		{
			title: "Скидка по промокоду",
			dataIndex: "discount",
			render: (_, record) => {
				return new Intl.NumberFormat("ru-RU", {
					style: "currency",
					notation: "standard",
					maximumFractionDigits: 0,
					currency: "RUB",
				}).format(record.discount)
			},
		},
		{
			title: "Промокод",
			dataIndex: "promocode",
			render: (_, record) =>
				record?.promocode && (
					<Link to={`/promocode/${record.promocode?.id}`}>{record.promocode.title || ""}</Link>
				),
		},
		{
			title: "Платеж",
			dataIndex: "payment", //TODO
			render: (_, record) => {
				return new Intl.NumberFormat("ru-RU", {
					style: "currency",
					notation: "standard",
					maximumFractionDigits: 0,
					currency: "RUB",
				}).format(record.payment)
			},
		},

		{
			title: "Система оплаты",
			dataIndex: "system",
			render: (_, record) => {
				return GetPaymentType(record?.system, record?.amount)
			},
		},
	]

	if (props?.hidePayer !== true) columns.push(payerColumn)

	return (
		<>
			{props.hideHeader !== true && (
				<form
					className="PaymentPageForm"
					onSubmit={(e) => {
						e.preventDefault()
						paymentStore.getListFilterPayments()
					}}
				>
					<Row gutter={[15, 15]}>
						<Col span={3}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Сортировка</span>
								<Select
									style={{
										width: "100%",
									}}
									defaultValue={1}
									value={paymentStore.payments.filter.sortId}
									onChange={(e) => paymentStore.onChangeFilter("sortId", e)}
								>
									<Option value={1}>По убыванию</Option>
									<Option value={0}>По возрастанию</Option>
								</Select>
							</Space>
						</Col>

						<Col span={3}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Поиск по id</span>
								<Input
									placeholder="Введите id оплаты"
									allowClear
									onChange={(event) => paymentStore.onChangeFilter("id", event.target.value)}
								/>
							</Space>
						</Col>

						<Col span={9}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Отфильтровать по курсам</span>
								<Select
									placeholder="Выберите курсы"
									mode="multiple"
									allowClear
									style={{
										width: "100%",
									}}
									defaultValue={paymentStore.payments.filter.courses}
									value={paymentStore.payments.filter.courses}
									onChange={(e) => paymentStore.onChangeFilter("courses", e)}
								>
									{props.courses.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.title}
											{item?.city && item?.city !== "null" && <> ({item?.city})</>}
											{item?.date_start && item?.date_start !== "null" && (
												<> - старт {dateFormat(item?.date_start)}</>
											)}
										</Option>
									))}
								</Select>
							</Space>
						</Col>

						<Col span={9}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Исключение курсов</span>
								<Select
									placeholder="Выберите курсы"
									mode="multiple"
									allowClear
									style={{
										width: "100%",
									}}
									defaultValue={paymentStore.payments.filter.notBuyCourses}
									value={paymentStore.payments.filter.notBuyCourses}
									onChange={(e) => paymentStore.onChangeFilter("notBuyCourses", e)}
								>
									{props.courses.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.title}
											{item?.city && item?.city !== "null" && <> ({item?.city})</>}
											{item?.date_start && item?.date_start !== "null" && (
												<> - старт {dateFormat(item?.date_start)}</>
											)}
										</Option>
									))}
								</Select>
							</Space>
						</Col>

						<Col span={3}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Отфильтровать по оплатам</span>
								<Select
									style={{
										width: "100%",
									}}
									defaultValue={2}
									value={paymentStore.payments.filter.payed}
									onChange={(e) => paymentStore.onChangeFilter("payed", e)}
								>
									<Option value={2}>Все</Option>
									<Option value={1}>Только оплаченные</Option>
									<Option value={0}>Только неоплаченные</Option>
								</Select>
							</Space>
						</Col>

						<Col span={5}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Поиск по Фамилии/Имени</span>
								<Input
									placeholder="Введите Фамилию/Имя"
									allowClear
									onChange={(event) => paymentStore.onChangeFilter("payer_name", event.target.value)}
								/>
							</Space>
						</Col>

						<Col span={7}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span>Отфильтровать по датам</span>
								<RangePicker
									style={{
										width: "100%",
									}}
									locale={locale}
									format="DD.MM.YYYY"
									onChange={(e) => paymentStore.onChangeDate(e)}
								/>
							</Space>
						</Col>

						<Col span={4}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span style={{ visibility: "hidden" }}>Применить</span>
								<Button type="primary" htmlType="submit" block>
									Применить
								</Button>
							</Space>
						</Col>
					</Row>
				</form>
			)}
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={props.pagination}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
}

export default observer(PaymentsList)
