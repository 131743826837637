// Import Framework
import { Table } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import React, { useEffect } from "react"

import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

const WebinarsRegistrationList = (props) => {
	useEffect(() => {
		webinarsStore.getWebinarsRegistration(
			webinarsStore.limit,
			webinarsStore.offset,
			webinarsStore.idWebinarsRegistration,
		)
	}, [])

	const columns = [
		{
			title: "№",
			dataIndex: "key",
		},
		{
			title: "Имя",
			dataIndex: "name",
		},
		{
			title: "Фамилия",
			dataIndex: "surname",
		},
		{
			title: "E-mail",
			dataIndex: "email",
		},
		{
			title: "Телефон",
			dataIndex: "phone",
		},
		{
			title: "ВКонтакте",
			dataIndex: "vk",
		},
		{
			title: "Инстаграм",
			dataIndex: "instagram",
		},
		{
			title: "Дата регистрации",
			dataIndex: "date_reg_on_webinar",
		},
	]

	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={webinarsStore.paginationReg}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
}

export default observer(WebinarsRegistrationList)
