// Import Components
// Import Store
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import TitlePage from "components/TitlePage/TitlePage"
import UsersForm from "components/UsersList"

import { coursesStore } from "store/Courses/CoursesStore"
import { usersStore } from "store/Users/UsersStore"

export const Admins = observer(() => {
	useEffect(() => {
		usersStore.getAllUsers(usersStore.limit, usersStore.offset, "admins")
		coursesStore.getAllCourses()
	}, [])

	if (!usersStore.AdminsList) return <></>

	return (
		<>
			<TitlePage
				title="Cписок админов"
				buttonText="Добавить админа"
				buttonLink="/users/0"
				fileName="admins"
				listFull={usersStore.AdminsFullList}
				getList={usersStore.getListAdmins}
				store={usersStore}
			/>

			<UsersForm type="admins" dataSource={usersStore.AdminsList} items={usersStore.totalAdmins} />
		</>
	)
})
