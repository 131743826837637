// Import Framework
import { Button } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import { usersStore } from "store/Users/UsersStore"

const CuratorCourse = (props) => {
	const { courseId, userId } = props

	useEffect(() => {
		usersStore.getModalCuratorCourse(courseId, userId)
	}, [courseId, userId])

	const curator = usersStore.modalCuratorCourse?.list.find((item) => {
		return Number(item.user) === Number(userId)
	})

	return (
		<Button type="link" onClick={() => usersStore.openModalCuratorCourse(courseId, userId)}>
			{curator?.name ? (
				<>{curator?.name}</>
			) : (
				<>
					<span style={{ color: "red" }}>Выбрать куратора</span>
				</>
			)}
		</Button>
	)
}

export default observer(CuratorCourse)
