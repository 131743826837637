import { EditOutlined } from "@ant-design/icons"
import { Table, Typography } from "antd"
import { observer } from "mobx-react-lite"
import { NavLink } from "react-router-dom"

import { categoriesStore } from "store/Categories/CategoriesStore"

import "./CoursesList.scss"

const CoursesList = (props) => {
	const { store } = props

	if (!categoriesStore.listCategories) return <></>

	const columns = [
		{
			title: "id",
			dataIndex: "id",
		},
		{
			title: "Название курса",
			dataIndex: "title",
			render: (text, record) => {
				return (
					<NavLink to={`/courses/${record.id}`}>
						{text} {record?.city && record?.city !== "null" ? <>({record?.city})</> : null}
					</NavLink>
				)
			},
		},
		{
			title: "Категория",
			dataIndex: "category",
			render: (_, course) => {
				if (course) {
					const category = categoriesStore.listCategories?.find((category) => category.id === course.category)
					if (category?.name) {
						return category?.name
					}
				}
			},
		},
		{
			title: "Комментарий",
			dataIndex: "commentary",
		},
		{
			title: "Статус курса",
			dataIndex: "status",
			render(item) {
				let status
				if (Number(item) === 1) {
					status = "Активен"
				} else {
					status = "Не активен"
				}
				return <div>{status}</div>
			},
		},
		{
			title: "Дата создания",
			dataIndex: "date_create",
		},
		{
			title: "Дата изменения",
			dataIndex: "date_update",
		},
		{
			title: "Дата старта",
			dataIndex: "date_start",
		},
		{
			title: "Оплаты курса",
			dataIndex: "reg",
			render: (_, record) => {
				return (
					<NavLink
						to={`/courses/registration/${record.id}`}
						style={{
							marginRight: 8,
						}}
					>
						Оплатившие ({record.purchasesNumber})
					</NavLink>
				)
			},
		},
		{
			title: "Действия",
			dataIndex: "operation",
			width: "100px",
			render: (_, record) => {
				return (
					<NavLink to={`/courses/${record.id}`}>
						<EditOutlined />
					</NavLink>
				)
			},
		},
	]
	return (
		<>
			<Table
				dataSource={store.listCourses}
				columns={columns}
				pagination={store.pagination}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {store.total}</div>
		</>
	)
}

export default observer(CoursesList)
