// Import Framework
import { UploadOutlined } from "@ant-design/icons"
import { Button, Tooltip, Upload } from "antd"
import update from "immutability-helper"
// Import Store
import { observer } from "mobx-react-lite"
import { useCallback, useRef } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

const type = "DragableUploadList"
const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
	const ref = useRef(null)
	const index = fileList.indexOf(file)
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const { index: dragIndex } = monitor.getItem() || {}

			if (dragIndex === index) {
				return {}
			}

			return {
				isOver: monitor.isOver(),
				dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
			}
		},
		drop: (item) => {
			moveRow(item.index, index)
		},
	})
	const [, drag] = useDrag({
		type,
		item: {
			index,
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	drop(drag(ref))
	const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
	return (
		<div
			ref={ref}
			className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ""}`}
			style={{
				cursor: "move",
			}}
		>
			{file.status === "error" ? errorNode : originNode}
		</div>
	)
}

const UploadImage = ({ store }) => {
	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			// dragIndex - Элемент, который перемещали
			// hoverIndex - Элемент на место которого переносим
			const dragRow = store.lesson.images[dragIndex]
			store.lesson.images = update(store.lesson.images, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRow],
				],
			})
		},
		[store.lesson],
	)

	const itemRender = (originNode, file, currFileList) => (
		<DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} moveRow={moveRow} />
	)

	const updateParams = {
		fileList: store.lesson.images,
		accept: "image/jpeg, image/png, image/gif",
		maxCount: 10,
		listType: "picture-card",
		showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
		onChange: (info) => {
			store.lesson.images = info.fileList
		},
		beforeUpload: (file) => {
			return false
		},
		itemRender: itemRender,
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<Upload {...updateParams}>
				<Button icon={<UploadOutlined />} />
			</Upload>
		</DndProvider>
	)
}

export default observer(UploadImage)
