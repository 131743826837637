import { Table, Typography } from "antd"
import { NavLink, useNavigate } from "react-router-dom"

import dateFormat from "hooks/dateFormat"

export const CoursesRegistrationTable = ({ list, count }) => {
	const columns = [
		{
			title: "ID Оплаты",
			dataIndex: "payment_id",
			key: "payment_id",
		},
		{
			title: "ID Пользователя",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Пользователь",
			dataIndex: "name",
			key: "name",
			render: (_, { id, name, surname }) => (
				<NavLink to={`/users/${id}`}>
					{name}&nbsp;{surname}
				</NavLink>
			),
		},
		{
			title: "Номер телефона",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Дата создания оплаты",
			dataIndex: "payment_date_create",
			key: "payment_date_create",
			render: (_, { id, payment_date_create }) => (
				<span key={`date_create-${id}`}>{dateFormat(payment_date_create)}</span>
			),
		},
		{
			title: "Дата оплаты",
			dataIndex: "payment_date_payed",
			key: "payment_date_payed",
			render: (_, { id, payment_date_payed }) =>
				payment_date_payed ? (
					<span key={`date_payed-${id}`}>{dateFormat(payment_date_payed)}</span>
				) : (
					<span key={`date_payed-${id}`}>Не оплачено</span>
				),
		},
		{
			title: "Сумма платежа",
			dataIndex: "amount",
			key: "amount",
			render: (_, { id, amount }) => {
				return (
					<span key={`amount-${id}`}>
						{new Intl.NumberFormat("ru-RU", {
							style: "currency",
							notation: "standard",
							maximumFractionDigits: 0,
							currency: "RUB",
						}).format(amount)}
					</span>
				)
			},
		},
		{
			title: "Система оплаты",
			dataIndex: "system_pay",
			key: "system_pay",
			render: (_, { system_pay }) => <span key={`system_pay-${system_pay.id}`}>{system_pay.name}</span>,
		},
	]

	return (
		<>
			<Table dataSource={list} columns={columns} pagination={false} />
			<div style={{ marginTop: "30px" }}>Всего записей: {count}</div>
		</>
	)
}
