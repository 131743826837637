import { Button, Col, DatePicker, Input, Row, Select, Space } from "antd"
import { observer } from "mobx-react-lite"

const { RangePicker } = DatePicker
const { Option } = Select
export const Form = observer(({ store, listCourses }) => {
	return (
		<form
			onSubmit={(event) => {
				event.preventDefault()
				store.getFilterListPromocodes()
			}}
			style={{ marginBottom: "30px" }}
		>
			<Row gutter={[15, 15]}>
				<Col span={4}>
					<Space
						direction="vertical"
						style={{
							width: "100%",
						}}
					>
						<span>Поиск по промокоду</span>
						<Input
							allowClear
							placeholder="Введите промокод"
							value={store.filter.promocode}
							onChange={(event) => store.onChangeFilter("promocode", event.target.value)}
						/>
					</Space>
				</Col>

				<Col span={6}>
					<Space
						direction="vertical"
						style={{
							width: "100%",
						}}
					>
						<span>Сортировка по курсам</span>
						<Select
							placeholder="Выберите курсы"
							mode="multiple"
							allowClear
							style={{
								width: "100%",
							}}
							onChange={(e) => store.onChangeFilter("courses", e)}
						>
							{listCourses.map((course) => {
								return (
									<Option key={course.id} value={course.id}>
										(ID: {course.id}) {course.title}
										<br />
										{course?.city && course?.city !== "null" ? <>({course?.city})</> : null}
									</Option>
								)
							})}
						</Select>
					</Space>
				</Col>

				<Col span={6}>
					<Space
						direction="vertical"
						style={{
							width: "100%",
						}}
					>
						<span>Поиск по датам</span>
						<RangePicker
							style={{
								width: "100%",
							}}
							locale="ru-RU"
							format="DD.MM.YYYY"
							onChange={(event) => store.onChangeFilterDate(event)}
						/>
					</Space>
				</Col>

				<Col span={3}>
					<Space
						direction="vertical"
						style={{
							width: "100%",
						}}
					>
						<span style={{ visibility: "hidden" }}>Применить</span>
						<Button type="primary" htmlType="submit" block>
							Применить
						</Button>
					</Space>
				</Col>
			</Row>
		</form>
	)
})
