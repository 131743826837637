import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import CategoriesPage from "pages/CategoriesPage/CategoriesPage"
import CoursesPage from "pages/CoursesPage/CoursesPage"
import CoursesRegistrationPage from "pages/CoursesRegistrationPage/CoursesRegistrationPage"
import CreateCategoriesPage from "pages/CreateCategoriesPage/CreateCategoriesPage"
import CreateCoursesPage from "pages/CreateCoursesPage/CreateCoursesPage"
import CreateTeamPage from "pages/CreateTeamPage/CreateTeamPage"
import CreateUsersPage from "pages/CreateUsersPage/CreateUsersPage"
import CreateWebinarsPage from "pages/CreateWebinarsPage/CreateWebinarsPage"
import PageNotFound from "pages/NotFound/NotFound"
import PaymentPage from "pages/PaymentPage/PaymentPage"
import PersonalPage from "pages/PersonalPage/PersonalPage"
import CreatePromoCodePage from "pages/PromoCodePage/create/createPromoCodePage"
import PromoCodePage from "pages/PromoCodePage/list/promoCodePage"
import PaymentPromoCodePage from "pages/PromoCodePage/payments/PaymentPromoCodePage"
import TeamPage from "pages/TeamPage/TeamPage"
import UsersPage from "pages/UsersPage/UsersPage"
import WebinarsPage from "pages/WebinarsPage/WebinarsPage"
import WebinarsRegistrationPage from "pages/WebinarsRegistrationPage/WebinarsRegistrationPage"

import Middleware from "../Middleware"

const RoutesPrivate = () => {
	useEffect(() => {
		Middleware.checkToken()
		setInterval(() => {
			Middleware.checkToken()
		}, 30000)
	})

	return (
		<>
			<Routes>
				<Route path="/" element={<CoursesPage />} />

				<Route path="/courses" element={<CoursesPage />} />
				<Route path="/courses/:id" element={<CreateCoursesPage />} />
				<Route path="/courses/registration/:id" element={<CoursesRegistrationPage />} />

				<Route path="/categories/" element={<CategoriesPage />} />
				<Route path="/categories/:id" element={<CreateCategoriesPage />} />

				<Route path="/personal" element={<PersonalPage />} />

				<Route path="/payment" element={<PaymentPage />} />

				<Route path="/promocode" element={<PromoCodePage />} />
				<Route path="/promocode/:id" element={<CreatePromoCodePage />} />
				<Route path="/promocode/payment/:id" element={<PaymentPromoCodePage />} />

				<Route path="/admins" element={<UsersPage user={"admin"} />} />

				<Route path="/users" element={<UsersPage user={"user"} />} />
				<Route path="/users/:id" element={<CreateUsersPage />} />

				<Route path="/team" element={<TeamPage />} />
				<Route path="/team/:id" element={<CreateTeamPage />} />

				<Route path="/webinars" element={<WebinarsPage />} />
				<Route path="/webinars/registration/:id" element={<WebinarsRegistrationPage />} />
				<Route path="/webinars/:id" element={<CreateWebinarsPage />} />

				<Route path="*" element={<PageNotFound replace />} />
			</Routes>
		</>
	)
}

export default RoutesPrivate
