import { Button, Col, Input, InputNumber, Popconfirm, Row, Select, Space, message } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"

import { coursesStore } from "store/Courses/CoursesStore"

import "./CoursesPrice.scss"

const { Option } = Select
const { TextArea } = Input

const CoursesPrice = (props) => {
	if (!props.prices) return <></>
	if (props.prices && props.prices.length > 0) {
		const prices = props.prices
		const cancel = (e) => {}
		const confirm = (item) => {
			if (prices.find((price) => price.id !== item)) {
				prices.splice(item, 1)
				message.success("Удалено")
			} else {
				message.error("Не удалено!")
			}
		}

		return (
			<Row gutter={[24, 24]}>
				{prices.map((price, index) => {
					return (
						<Col
							key={index}
							xs={24}
							md={8}
							id={index}
							data-position={index}
							draggable
							onDragStart={(e) => coursesStore.onDragStart(e, "prices")}
							onDragOver={(e) => coursesStore.onDragOver(e, "prices")}
							onDrop={() => coursesStore.onDrop("prices")}
							onDragLeave={(e) => coursesStore.onDragLeave(e, "prices")}
							className={
								coursesStore.initStates.prices &&
								coursesStore.initStates.prices.draggedTo === Number(index)
									? "dropArea dropItem"
									: "dropItem"
							}
						>
							<Space className="drag" direction="vertical">
								<strong>Предложение №{index + 1}</strong>
								<Input
									placeholder="Название"
									value={coursesStore.course.prices[index].name}
									onChange={(e) => coursesStore.onChangePrice("name", index, e.target.value)}
								/>

								<Row gutter={[15, 15]}>
									<Col xs={24} md={12}>
										<label>Цена</label>
										<InputNumber
											placeholder="Цена"
											min={0}
											controls={false}
											value={coursesStore.course.prices[index].amount}
											onChange={(e) => coursesStore.onChangePrice("amount", index, e)}
										/>
									</Col>

									<Col xs={24} md={12}>
										<label>со скидкой</label>
										<InputNumber
											placeholder="Цена со скидкой"
											min={0}
											controls={false}
											value={coursesStore.course.prices[index].discount}
											onChange={(e) => coursesStore.onChangePrice("discount", index, e)}
										/>
									</Col>
								</Row>

								<TextArea
									placeholder="Описание"
									value={coursesStore.course.prices[index].description}
									autoSize={{ minRows: 3 }}
									onChange={(e) => {
										coursesStore.onChangePrice("description", index, e.target.value)
									}}
								/>

								<label>Тип оплаты</label>
								<Select
									defaultValue={"0"}
									value={coursesStore.course.prices[index].system}
									onChange={(e) => coursesStore.onChangePrice("system", index, e)}
								>
									<Option value="0" disabled>
										Не выбрано
									</Option>
									<Option value="1">Сбер</Option>
									<Option value="3">Рассрочка MK</Option>
									{/*<Option value="2">Рассрочка Сбер</Option>*/}
									{/*<Option value="4">Карта</Option>*/}
									{/*<Option value="5">Рассрочка Тинькофф</Option>*/}
								</Select>

								<Popconfirm
									title="Точно удалить цену?"
									onConfirm={() => confirm(index)}
									onCancel={cancel}
									okText="Удалить"
									cancelText="Отменить"
								>
									<Button type="danger">Удалить</Button>
								</Popconfirm>
							</Space>
						</Col>
					)
				})}
			</Row>
		)
	}
}

export default observer(CoursesPrice)
