import { message } from "antd"
import { action, makeAutoObservable, toJS } from "mobx"
import React from "react"

import { coursesStore } from "store/Courses/CoursesStore"
import { paymentStore } from "store/PaymentStore/PaymentStore"
import locals from "store/locals"

import dateFormat from "hooks/dateFormat"

import axios from "../../axios"

class UsersStore {
	UsersList = []
	AdminsList = []
	newUser = {}
	createItemUser = {}
	editItemUser = {}
	newCountry = {}
	totalUsers = 0
	totalAdmins = 0
	limit = 15
	offset = 0
	pageSize = 15
	type = ""

	createUserStatus = false
	editUserStatus = false

	statusEditUser = true

	addressInput = ""
	ymapsInput = ""
	address = ""
	AdminsFullList = []
	UsersFullList = []
	statusCSV = false

	newPaymentModalVisible = false
	newPayment = {}
	payerID = 0
	modalCuratorCourse = {
		status: false,
		course: null,
		curator: null,
		curatorId: null,
		user: null,
		list: [],
	}

	filter = {
		status: 0,
		sortId: 0, // false - ASC, true - DESC
		purchased_courses: [], // Array id courses
		notBuyCourses: [], // Array id courses
		filter: {
			name: "",
			surname: "",
			phone: "",
			email: "",
			city: "",
		},
	}

	constructor() {
		makeAutoObservable(this)
		this.addressInput = React.createRef()
	}

	onYmapsLoad = (ymaps) => {
		this.ymaps = ymaps
		new this.ymaps.SuggestView(this.addressInput.current, {
			provider: {
				suggest: (request, options) => this.ymaps.suggest(request),
			},
		})
	}

	paginationAdmins = {
		pageSize: this.pageSize,
		total: this.totalAdmins,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			this.offset = this.getAllUsers(this.newItems, offset, "admins")
		},
	}

	paginationUsers = {
		pageSize: this.pageSize,
		total: this.totalUsers,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize

			if (this.filter.status === 0) {
				this.offset = this.getAllUsers(this.newItems, offset, "users")
			} else {
				this.offset = this.getFiltersUsers(this.newItems, offset, "users")
			}
		},
	}

	onChange = (name, value) => {
		this.newUser[name] = value
	}

	onChangeFilter = (name, value) => {
		this.filter[name] = value
	}

	onChangeFilterInput = (name, value) => {
		this.filter.filter[name] = value
	}

	onChangeModalCuratorCourse = (value) => {
		this.modalCuratorCourse.curatorId = value
	}

	fullListUsers = (name, list) => {
		this[name] = list.map((item) => {
			return {
				ID: item.id,
				Имя: item.name,
				Фамилия: item.surname,
				Телефон: item.phone,
				email: item.email,
				Страна: item.country,
				Город: item.city,
				"Дата регистрации": dateFormat(item.date_reg),
			}
		})

		this.statusCSV = true
		setTimeout(
			action(() => {
				this.statusCSV = false
			}),
			1000,
		)
	}

	getAllUsers = (limit = this.limit, offset = this.offset, type) => {
		this.paginationUsers.total = 0
		this.paginationAdmins.total = 0
		axios
			.get("/user/" + type, {
				params: {
					limit: limit,
					offset: offset,
				},
			})
			.then(
				action((success) => {
					const [users, count] = success.data

					if (type === "admins") {
						this.totalAdmins = count[0].count
						this.type = type
						this.paginationAdmins.total = this.totalAdmins
						this.AdminsList = users.map((user, index) => {
							return {
								key: index + 1,
								...user,
								date_reg: user?.date_reg ? dateFormat(user?.date_reg) : null,
								date_update: user?.date_update ? dateFormat(user?.date_update) : null,
							}
						})
					}

					if (type === "users") {
						this.totalUsers = count[0].count
						this.type = type
						this.paginationUsers.total = this.totalUsers
						this.UsersList = users.map((user, index) => {
							return {
								key: index + 1,
								...user,
								date_reg: user?.date_reg ? dateFormat(user?.date_reg) : null,
								date_update: user?.date_update ? dateFormat(user?.date_update) : null,
							}
						})
					}
				}),
			)
	}

	getFiltersUsers = (limit = this.limit, offset = this.offset) => {
		const filterList = []

		if (this?.filter?.filter?.name) {
			filterList?.push({
				name: "name",
				value: this?.filter?.filter?.name,
			})
		}

		if (this?.filter?.filter?.surname) {
			filterList?.push({
				name: "surname",
				value: this?.filter?.filter?.surname,
			})
		}

		if (this?.filter?.filter?.city) {
			filterList?.push({
				name: "city",
				value: this?.filter?.filter?.city,
			})
		}

		if (this?.filter?.filter?.phone) {
			filterList?.push({
				name: "phone",
				value: this?.filter?.filter?.phone,
			})
		}

		if (this?.filter?.filter?.email) {
			filterList?.push({
				name: "email",
				value: this?.filter?.filter?.email,
			})
		}

		if (this?.filter?.notBuyCourses) {
			filterList?.push({ name: "notBuyCourses", value: this.filter.notBuyCourses })
		}

		const params = {
			limit: limit,
			offset: offset,
			filter: JSON.stringify(filterList),
			purchased_courses: JSON.stringify(this.filter.purchased_courses),
			sort_id: this.filter.sortId,
		}

		axios
			.get("/user/users", {
				params,
			})
			.then(
				action((success) => {
					this.filter.status = 1
					const [users, count] = success.data
					this.totalUsers = count[0].count
					this.paginationUsers.total = this.totalUsers
					this.UsersList = users.map((user, index) => {
						return {
							key: index + 1,
							...user,
							date_reg: user?.date_reg ? dateFormat(user?.date_reg) : null,
							date_update: user?.date_update ? dateFormat(user?.date_update) : null,
						}
					})
				}),
			)
	}

	getListAdmins = () => {
		axios
			.get("/user/admins", {
				params: {
					limit: this.totalAdmins,
				},
			})
			.then(
				action((success) => {
					const [users] = success.data

					if (users?.length > 0) {
						this.fullListUsers("AdminsFullList", users)
					} else {
						this.UsersFullList = []
					}
				}),
			)
	}

	getListUsers = ({ limit } = {}) => {
		const filterList = []
		const filters = this?.filter?.filter

		if (filters) {
			for (const key in filters) {
				if (!filters?.[key]) continue
				filterList?.push({
					name: key,
					value: filters?.[key],
				})
			}
		}

		if (this?.filter?.notBuyCourses) {
			filterList?.push({ name: "notBuyCourses", value: this.filter.notBuyCourses })
		}

		const params = {
			limit: limit ?? this.totalUsers,
			filter: JSON.stringify(filterList),
			purchased_courses: JSON.stringify(this.filter.purchased_courses),
			sort_id: this.filter.sortId,
		}

		axios
			.get("/user/users", {
				params,
			})
			.then(
				action((success) => {
					const [users] = success.data

					if (users?.length > 0) {
						this.fullListUsers("UsersFullList", users)
					} else {
						this.UsersFullList = []
					}
				}),
			)
	}

	getUser = (id) => {
		axios
			.get(`/user/user/${id}`)
			.then(
				action((success) => {
					this.newUser = success.data
				}),
			)
			.catch((error) => {
				if (error?.request?.status === 400) {
					window.location.href = "/users"
				}
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	getUserEditor = (id, typeUser) => {
		axios
			.get(`/user/user/${id}`)
			.then(
				action((success) => {
					if (typeUser === "create") {
						this.createUserStatus = true
						this.createItemUser = success.data
					}
					if (typeUser === "edit") {
						this.editUserStatus = true
						this.editItemUser = success.data
					}
				}),
			)
			.catch((error) => {
				if (error?.request?.status === 400) {
					if (typeUser === "create") {
						this.createUserStatus = false
					}

					if (typeUser === "edit") {
						this.editUserStatus = false
					}
					throw new Error(`Ошибка ${error.status}`)
				}
			})
	}

	setUser = () => {
		this.newUser = {
			id: 0,
			name: "",
			surname: "",
			login: "",
			phone: "",
			email: "",
			password: "",
			passwordConfirmation: "",
			city: "",
			country: "",
			permissions: "",
			curator: "",
			vk: "",
			instagram: "",
		}
	}

	UserDelete = (key, type) => {
		axios.delete(`/user/user/${key}`).then(
			action((success) => {
				if (success.data === 1) {
					message.success("Успешно")
					if (type === true) {
						this.getAllUsers(this.limit, this.offset, "admins")
					} else {
						this.getAllUsers(this.limit, this.offset, "users")
					}
				} else {
					message.error("Ошибка")
				}
			}),
		)
	}

	UserSignUp = (e) => {
		e.preventDefault()
		let user = new FormData()

		let url

		if (this.newUser.id > 0) {
			url = `edit/${this.newUser.id}`
		} else {
			url = "new"
		}

		if (this.newUser.city) {
			this.newUser.city = this.newUser.city.charAt(0).toUpperCase() + this.newUser.city.slice(1)
			user.append("city", this.newUser.city)
		}

		if (this.newUser.country) {
			this.newUser.country = this.newUser.country.charAt(0).toUpperCase() + this.newUser.country.slice(1)
			user.append("country", this.newUser.country)
		}

		user.append("name", this.newUser.name)
		user.append("surname", this.newUser.surname)
		user.append("phone", this.newUser.phone)
		user.append("email", this.newUser.email)
		user.append("vk", this.newUser.vk)
		user.append("instagram", this.newUser.instagram)

		if (this.newUser.login === null) {
			user.append("login", "")
		} else {
			user.append("login", this.newUser.login)
		}

		if (this.newUser.curator === null) {
			user.append("curator", "")
		} else {
			user.append("curator", this.newUser.curator)
		}

		if (this.newUser.id === 0) {
			if (this.newUser.permissions > 0 && this.newUser.login.length > 0) {
				user.append("permissions", this.newUser.permissions)
			} else if (this.newUser.permissions === 0) {
				user.append("permissions", 0)
			} else if (this.newUser.permissions.length === 0) {
				return message.error(`Необходимо указать права`)
			} else {
				return message.error(`Необходимо указать Логин`)
			}
		} else {
			if (this.newUser.permissions > 0 && this.newUser.login.length > 0) {
				user.append("permissions", this.newUser.permissions)
			} else if (this.newUser.permissions === 0) {
				user.append("permissions", 0)
			} else if (this.newUser.permissions > 0) {
				if (this.newUser.permissions > 0 && this.newUser.login.length > 0) {
					user.append("permissions", this.newUser.permissions)
				} else {
					return message.error(`Необходимо указать Логин`)
				}
			} else if (this.newUser.permissions.length === 0) {
				return message.error(`Необходимо указать права`)
			}
		}

		if (this.newUser.id === 0) {
			const isLengthPassword = this.newUser.password.length > 0
			const isPasswordConfirmed = this.newUser.password === this.newUser.passwordConfirmation
			if (!isPasswordConfirmed) {
				return message.error("Пароли не совпадают")
			}
			if (!isLengthPassword) {
				return message.error("Необходимо задать пароль пользователю")
			}
			if (isLengthPassword && isPasswordConfirmed) {
				user.append("password", this.newUser.password)
			}
		} else {
			if (this.newUser.password === this.newUser.passwordConfirmation) {
				user.append("password", this.newUser.password)
			} else if (this.newUser.password !== this.newUser.passwordConfirmation) {
				return message.error("Пароль неверный")
			}
		}

		axios
			.post(`/user/${url}`, user)
			.then(
				action((success) => {
					message.success("Успешно")
					document.location.href = "/users"
				}),
			)
			.catch((error) => {
				{
					throw new Error(`Ошибка ${error.status}`)
				}
			})
	}

	postUser = (e) => {
		e.preventDefault()
		let userPost = new FormData()
		this.address = this.addressInput.current.value.split(",")

		userPost.append("name", this.newUser.name)

		if (this.address.length === 2) {
			userPost.append("country", this.address[0].trim())
			userPost.append("city", this.address[1].trim())
		} else if (this.address.length >= 3) {
			userPost.append("country", this.address[0].trim())
			userPost.append("city", this.address[2].trim())
		}

		if (!this.newUser.vk) {
			this.statusEditUser = false
			return message.error("Введите url на страницу VK")
		}

		if (!this.newUser.instagram) {
			this.statusEditUser = false
			return message.error("Введите url на страницу Instagram")
		}

		userPost.append("vk", this.newUser.vk)
		userPost.append("instagram", this.newUser.instagram)

		if (typeof this.newUser.password !== "undefined") {
			if (this.newUser.password === this.newUser.passwordConfirmation) {
				userPost.append("password", this.newUser.password)
			} else if (!this.newUser.password && !this.newUser.passwordConfirmation) {
				this.newUser.password = ""
				this.newUser.passwordConfirmation = ""
				return true
			} else if (this.newUser.password !== this.newUser.passwordConfirmation) {
				this.newUser.password = ""
				this.statusEditUser = false
				return message.error("Пароли не совпадают")
			}
		}

		axios
			.post(`/user/edit/${locals.get("user").id}`, userPost)
			.then(
				action((success) => {
					message.success("Обновлено!")
				}),
			)
			.catch((error) => {
				{
					throw new Error(`Ошибка ${error.status}`)
				}
			})
	}

	editUser = () => {
		this.statusEditUser = false
	}

	saveUser = () => {
		this.statusEditUser = true
	}

	openModalCuratorCourse = (courseId, userId) => {
		this.modalCuratorCourse.status = true
		this.modalCuratorCourse.course = courseId
		this.modalCuratorCourse.user = userId
		this.modalCuratorCourse.curator = null

		axios
			.get(`/courses/curator/?course=${courseId}&user=${userId}`)
			.then(
				action((success) => {
					if (success.data !== null) {
						this.modalCuratorCourse.curator = success.data
					}
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	getModalCuratorCourse = (courseId, userId) => {
		this.modalCuratorCourse.list = []

		axios
			.get(`/courses/curator/?course=${courseId}&user=${userId}`)
			.then(
				action((success) => {
					this.modalCuratorCourse.list.push({ user: userId, ...success.data })
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	updateModalCuratorCourse = action((e) => {
		e.preventDefault()

		this.modalCuratorCourse.status = false

		if (this.modalCuratorCourse.curatorId === null) return

		let curator = new FormData()
		curator.append("course", this.modalCuratorCourse.course)
		curator.append("user", this.modalCuratorCourse.user)
		curator.append("curator", this.modalCuratorCourse.curatorId)
		axios
			.post("/courses/curator", curator)
			.then((success) => {
				message.success("Куратор добавлен")
				this.modalCuratorCourse.course = null
				this.modalCuratorCourse.user = null
				this.modalCuratorCourse.curatorId = null
			})
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	})

	closeModalCuratorCourse = () => {
		this.modalCuratorCourse.status = false
		this.modalCuratorCourse.course = null
		this.modalCuratorCourse.user = null
		this.modalCuratorCourse.curatorId = null
	}

	setNewPaymentModal = () => {
		this.newPayment = {
			amount: 0,
			system: "1",
			ext_id: "",
			comment: "",
		}
		this.newPaymentModalVisible = !this.newPaymentModalVisible
	}

	onChangeNewPayment = (name, value) => {
		if (name === "courseID") {
			let courseName = coursesStore.listCourses.find((item) => item.id === value).title
			this.newPayment.comment = "Обучение " + courseName
		}
		this.newPayment[name] = value
	}

	makeNewPayment = () => {
		let fd = new FormData()

		if (this.newPayment.courseID > 0) fd.append("courseID", this.newPayment.courseID)
		else return message.error("Необходимо выбарть курс")

		fd.append("payerID", this.payerID)
		fd.append("amount", this.newPayment.amount)
		fd.append("system", this.newPayment.system)
		fd.append("comment", this.newPayment.comment)
		fd.append("ext_id", this.newPayment.ext_id)

		axios.post("/payments/manual", fd).then((success) => {
			if (success.data === 1) {
				this.newPaymentModalVisible = false
				paymentStore.getUserPayments(this.payerID, 99999, 0)
			} else {
				message.error("Произошла ошибка")
			}
		})
	}
}

export const usersStore = new UsersStore()
