// Import Framework
import SEO from "@americanexpress/react-seo"
import { FileTextOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons"
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Input,
	Modal,
	Row,
	Select,
	Space,
	Switch,
	Tabs,
	Typography,
	Upload,
	message,
} from "antd"
import locale from "antd/es/date-picker/locale/ru_RU"
import { observer } from "mobx-react-lite"
import moment from "moment"
import "moment/locale/ru"
// Import Components
import { useEffect } from "react"
import { CSVDownload, CSVLink } from "react-csv"
import { Editor } from "react-draft-wysiwyg"
import { useParams } from "react-router-dom"

import CoursesNumbers from "components/Courses/CoursesNumbers/CoursesNumbers"
import CoursesPrice from "components/Courses/CoursesPrice/CoursesPrice"
import PaymentsCourses from "components/Courses/PaymentsCourses"
import HeaderComponent from "components/Header/Header"
import Lessons from "components/Lessons/Lessons"
import UploadImage from "components/Lessons/UI/UploadImage"

import { categoriesStore } from "store/Categories/CategoriesStore"
// Import Store
import { coursesStore as store } from "store/Courses/CoursesStore"
import { lessonsStore } from "store/Lessons/LessonsStore"
import { teamStore } from "store/Team/TeamStore"
import { usersStore } from "store/Users/UsersStore"

// Import Hooks
import dateFormat from "hooks/dateFormat"

// Import Style
import "./CreateCoursesPage.scss"

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs
const CreateCoursesPage = () => {
	const params = useParams()
	const { prices, in_numbers, date_create, date_update, created_by, updated_by } = store.course

	const addNewPrice = () => {
		if (prices && prices.length >= 0) {
			prices.push({
				amount: "",
				description: "",
				discount: "",
				name: "",
				position: prices.length + 1,
			})
		}
	}

	const addNewNumber = () => {
		if (in_numbers && in_numbers.length >= 0) {
			in_numbers.push({
				num: "",
				text: "",
				postition: in_numbers.length + 1,
			})
		}
	}

	const uploadСoverProps = {
		fileList: store.course.photo,
		accept: "image/jpeg, image/png, image/gif",
		maxCount: 1,
		listType: "picture-card",
		showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
		onRemove: (file) => {
			store.course.photo = []
		},
		beforeUpload: (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif"

			if (!isJpgOrPng) {
				message.error("Можно загружать только JPG/PNG/GIF файл!")
			}
			store.course.photo = [file]
			return false
		},
	}

	const uploadGuideProps = {
		fileList: store.course.guide_file,
		accept: "application/pdf",
		maxCount: 1,
		listType: "text",
		showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
		onRemove: (file) => {
			store.course.guide_file = []
		},
		beforeUpload: (file) => {
			const isPDF = file.type === "application/pdf"

			if (!isPDF) {
				message.error("Можно загружать только PDF файл!")
			}
			store.course.guide_file = [file]
			return false
		},
	}

	// const propsImage = {
	// 	fileList: lessonsStore.lesson.images,
	// 	accept: "image/jpeg, image/png, image/gif",
	// 	maxCount: 10,
	// 	listType: "picture-card",
	// 	showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
	// 	onChange: (info) => {
	// 		lessonsStore.lesson.images = info.fileList
	// 	},
	// 	beforeUpload: (file) => {
	// 		return false
	// 	},
	// }

	useEffect(() => {
		teamStore.getAllTeam()
		store.getAllCourses()
		if (params.id > 0) {
			lessonsStore.getLessons(Number(params.id))
			store.getCourse(Number(params.id))
			store.getUserList(params.id)
			usersStore.getListUsers()
		} else {
			store.setCourse()
		}
		if (created_by) {
			usersStore.getUserEditor(created_by, "create")
		}
		if (updated_by) {
			usersStore.getUserEditor(updated_by, "edit")
		}
	}, [store, params.id, created_by, updated_by])

	const onSearch = (value) => {
		// eslint-disable-next-line no-console
		console.log("search:", value)
	}

	return (
		<>
			<SEO title={params.id > 0 ? "Редактирование курса" : "Создание курса"} />
			<HeaderComponent />
			<div className="container">
				<form onSubmit={(e) => store.editCourse(e)} className="CreateCourses">
					<Row className="CreateCourses__row" gutter={[15, 24]}>
						<Col xs={24} lg={18} className="CreateCourses__title">
							<Row gutter={[15, 24]} justify="space-between">
								<Col xs={24} md={20}>
									<Title>{params.id > 0 ? "Редактирование курса" : "Создание курса"}</Title>
								</Col>

								<Col xs={24} md={4} className="CreateCourses__title-block">
									<Space>
										{params.id > 0 && (
											<Button
												size="large"
												href={"https://student.modnayakontora.ru/courses/" + params.id}
												target="_blank"
											>
												Просмотр
											</Button>
										)}
										<Button
											size="large"
											type="primary"
											htmlType="submit"
											className="CreateCourses__title-button"
										>
											{params.id > 0 ? "Сохранить" : "Создать"}
										</Button>
									</Space>
								</Col>
							</Row>
						</Col>

						<Col xs={24} lg={18}>
							<Row gutter={[15, 24]}>
								<Col xs={24} md={14}>
									<Space direction="vertical">
										<Title level={4}>Название</Title>
										<TextArea
											size="large"
											placeholder="Название"
											value={store.course.title}
											onChange={(e) => store.onChange("title", e.target.value)}
										/>
									</Space>
								</Col>

								<Col xs={12} md={6} className="CreateCourses__switch">
									<Space direction="vertical">
										<Title level={4}>Статус курса</Title>
										<Switch
											className="CreateCourses__switch-switch"
											checked={Boolean(Number(store.course.status))}
											checkedChildren="Активен"
											unCheckedChildren="Не активен"
											onChange={(e) => {
												if (e === true) {
													store.onChange("status", 1)
												} else {
													store.onChange("status", 0)
												}
											}}
										/>
									</Space>
								</Col>

								{params.id > 0 && (
									<Col xs={12} md={4} className="CreateCourses__switch">
										<Space direction="vertical">
											<Title level={4} style={{ visibility: "hidden" }}>
												Статус курса
											</Title>
											<Button
												size="large"
												type="dashed"
												onClick={(event) => store.cloneCourse(event, params.id)}
												block
											>
												Дублирование курса
											</Button>
										</Space>
									</Col>
								)}
							</Row>
						</Col>

						<Col xs={24} lg={18}>
							<Tabs defaultActiveKey="1" type="card">
								<TabPane tab="Основная информация" key="1" className="CreateCourses__Tab-One">
									<Row gutter={[15, 24]}>
										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Категория</Title>
												<Select
													size="large"
													placeholder="Выберите категорию"
													value={store.course.category}
													onChange={(e) => store.onChange("category", e)}
												>
													{categoriesStore.listCategories.map((category, index) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														)
													})}
												</Select>
											</Space>
										</Col>

										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Тип курса</Title>
												<Input
													size="large"
													placeholder="Тип курса"
													value={store.course.course_type}
													onChange={(e) => store.onChange("course_type", e.target.value)}
												/>
											</Space>
										</Col>

										<Col className="CreateCourses__city" xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Город</Title>
												<Input
													size="large"
													placeholder="Город проведения"
													value={store.course.city}
													onChange={(e) => store.onChange("city", e.target.value)}
												/>
											</Space>
										</Col>
									</Row>

									<Row gutter={[15, 24]}>
										<Col xs={24} sm={12} md={8}>
											<Space direction="vertical">
												<Title level={4}>Дата старта</Title>
												<DatePicker
													size="large"
													locale={locale}
													value={
														store.course.date_start
															? store.setDate(store.course.date_start)
															: ""
													}
													format="L"
													onChange={(e) => store.onChangeDate("date_start", e)}
												/>
											</Space>
										</Col>

										<Col xs={24} sm={12} md={8}>
											<Space direction="vertical">
												<Title level={4}>Активен до</Title>
												<DatePicker
													size="large"
													locale={locale}
													value={
														store.course.date_active
															? store.setDate(store.course.date_active)
															: ""
													}
													format="L"
													onChange={(e) => store.onChangeDate("date_active", e)}
												/>
											</Space>
										</Col>

										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Комментарий</Title>
												<Input
													size="large"
													placeholder="Комментарий"
													value={store.course.commentary}
													onChange={(e) => store.onChange("commentary", e.target.value)}
												/>
											</Space>
										</Col>
									</Row>

									<Row gutter={[15, 24]}>
										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Обложка</Title>
												<Upload {...uploadСoverProps}>
													<Button icon={<UploadOutlined />} />
												</Upload>
											</Space>
										</Col>

										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Видео YouTube</Title>
												<Input
													size="large"
													placeholder="Видео YouTube"
													value={store.course.course_video}
													onChange={(e) => store.onChange("course_video", e.target.value)}
												/>
											</Space>
										</Col>
										<Col xs={24} md={8}>
											<Space direction="vertical">
												<Title level={4}>Гайд файл</Title>
												<Upload {...uploadGuideProps}>
													<Button icon={<UploadOutlined />} />
												</Upload>
											</Space>
										</Col>
									</Row>

									<Row gutter={[15, 24]}>
										<Col className="CreateCourses__description-link" xs={24}>
											<Space direction="vertical">
												<Title level={4}>Ссылка на курс на сайте</Title>
												<Input
													size="large"
													placeholder="Введите ссылку на курс"
													value={store.course.site_course_url}
													onChange={(e) => store.onChange("site_course_url", e.target.value)}
												/>
											</Space>
										</Col>

										<Col className="CreateCourses__description-short" xs={24}>
											<Space direction="vertical">
												<Title level={4}>Описание для главной</Title>
												<TextArea
													showCount
													maxLength={250}
													autoSize={{
														minRows: 4,
														maxRows: 5,
													}}
													value={store.course.description}
													onChange={(e) => store.onChange("description", e.target.value)}
												/>
											</Space>
										</Col>
									</Row>

									<Row className="CreateCourses__numbers" gutter={[15, 15]}>
										<Col xs={24} className="CreateCourses__numbers-title">
											<Space align="center" size="large">
												<Title level={4}>Курс в цифрах</Title>
												<Button icon={<PlusOutlined />} onClick={addNewNumber} size="middle">
													Добавить новый
												</Button>
											</Space>
										</Col>

										<Col xs={24}>
											<CoursesNumbers in_numbers={in_numbers} />
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Лендинг" key="2">
									<Row gutter={[15, 24]}>
										<Col xs={24}>
											<Space direction="vertical">
												<Title level={4}>Заголовок описания</Title>
												<Input
													size="large"
													placeholder="Название"
													value={store.course.desc_heading}
													onChange={(e) => store.onChange("desc_heading", e.target.value)}
												/>
											</Space>
										</Col>

										<Col className="CreateCourses__description-full" xs={24}>
											<Space direction="vertical">
												<Title level={4}>Полное описание</Title>
												<TextArea
													autoSize={{
														minRows: 5,
													}}
													value={store.course.full_description}
													onChange={(e) => store.onChange("full_description", e.target.value)}
												/>
											</Space>
										</Col>

										<Col className="CreateCourses__team" xs={24} md={12}>
											<Space direction="vertical">
												<Title level={4}>Команда курса</Title>
												<Select
													size="large"
													mode="multiple"
													allowClear
													placeholder="Выберите команду"
													defaultValue={store.course.team}
													value={store.course.team}
													onChange={(e) => store.onChange("team", e)}
												>
													{teamStore.listTeam.map((team, index) => {
														return (
															<Option key={team.id} value={team.id}>
																{team.name}
															</Option>
														)
													})}
												</Select>
											</Space>
										</Col>

										<Col className="CreateCourses__related_courses" xs={24} md={12}>
											<Space direction="vertical">
												<Title level={4}>Связанные курсы</Title>

												<Select
													size="large"
													placeholder="Выберите курсы"
													mode="multiple"
													allowClear
													defaultValue={store.course.related_courses}
													value={store.course.related_courses}
													onChange={(e) => store.onChange("related_courses", e)}
												>
													{store.listCourses.map((course, index) => {
														return (
															<Option key={course.id} value={course.id}>
																{course.title}{" "}
																{course?.city && course?.city !== "null" ? (
																	<>({course?.city})</>
																) : null}
															</Option>
														)
													})}
												</Select>
											</Space>
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Цены" key="3">
									<Row className="CreateCourses__prices" gutter={[15, 15]}>
										<Col xs={24} className="CreateCourses__prices-title">
											<Space align="center" size="large">
												<Title level={4}>Предложения</Title>
												<Button icon={<PlusOutlined />} onClick={addNewPrice} size="middle">
													Добавить новое
												</Button>
											</Space>
										</Col>

										<Col xs={24} className="CreateCourses__prices-content">
											<CoursesPrice prices={prices} />
										</Col>
									</Row>
								</TabPane>

								{params.id > 0 && (
									<>
										<TabPane tab="Лекции" key="4">
											<Lessons courseId={params.id} lessons={lessonsStore.listLessons} />
										</TabPane>

										<TabPane tab="Оплатившие курс" key="5">
											<CSVLink
												data={store.userListCSV}
												filename={`Пользователи курса №${params.id}.csv`}
												separator={";"}
												enclosingCharacter={``}
												uFEFF={true}
												target="_blank"
												onClick={(event, done) => {
													store.getUserListCSV()
												}}
												className="CreateCourses__prices-csvLink"
											>
												<FileTextOutlined /> Скачать в CSV
											</CSVLink>

											<PaymentsCourses
												userList={store.userList}
												courseId={Number(params.id)}
												store={store}
											/>
										</TabPane>
									</>
								)}
							</Tabs>
						</Col>
					</Row>
				</form>
				{params.id > 0 && (
					<div style={{ paddingBottom: "30px" }}>
						<Divider />
						<Row gutter={[24, 24]} justify="center">
							{usersStore.createUserStatus === true && (
								<Col xs={24} sm={12} md={8}>
									<div>
										<strong>Дата создания:</strong> {dateFormat(date_create)}
									</div>
									<div>
										<strong>Кем было создано:</strong> {usersStore.createItemUser.name}
									</div>
								</Col>
							)}

							{usersStore.editUserStatus === true && (
								<Col xs={24} sm={12} md={8}>
									<div>
										<strong>Дата изменния:</strong> {dateFormat(date_update)}
									</div>
									<div>
										<strong>Кем было изменено:</strong> {usersStore.editItemUser.name}
									</div>
								</Col>
							)}
						</Row>
					</div>
				)}
			</div>
			<Modal
				title="Добавить лекцию"
				open={lessonsStore.modalView}
				onCancel={() => lessonsStore.closeModal(params.id)}
				footer={false}
				className="CreateLesson"
				width={650}
			>
				<form onSubmit={(e) => lessonsStore.addLesson(e, params.id)}>
					<Space direction="vertical">
						<div className="CreateLesson__group">
							<Title level={4}>Название</Title>
							<Input
								size="large"
								placeholder="Введите название"
								value={lessonsStore.lesson.title}
								onChange={(e) => lessonsStore.onChange("title", e.target.value)}
							/>
						</div>
						<Row gutter={30}>
							<Col xs={24} md={12}>
								<div className="CreateLesson__group">
									<Title level={4}>Дата публикации</Title>
									<DatePicker
										style={{ width: "100%" }}
										size="large"
										locale={locale}
										showTime={{
											defaultValue: moment("10:00", "HH:mm"),
										}}
										value={
											lessonsStore.lesson.date_active
												? lessonsStore.setDate(lessonsStore.lesson.date_active)
												: ""
										}
										format="DD.MM.YYYY HH:mm"
										onChange={(e) => lessonsStore.onChangeDate("date_active", e)}
									/>
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="CreateLesson__group">
									<Title level={4}>Статус лекции</Title>
									<Switch
										className="CreateLesson__group-switch"
										checked={Boolean(Number(lessonsStore.lesson.status))}
										checkedChildren="Активен"
										unCheckedChildren="Не активен"
										onChange={(e) => {
											if (e === true) {
												lessonsStore.onChange("status", 1)
											} else {
												lessonsStore.onChange("status", 0)
											}
										}}
									/>
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="CreateLesson__group">
									<Title level={4}>Формат</Title>
									<Select
										style={{ width: "100%" }}
										size="large"
										placeholder="Выберите формат"
										value={String(lessonsStore.lesson.format)}
										onChange={(e) => lessonsStore.onChange("format", e)}
									>
										<Option value="1">Горизонтальное видео</Option>
										<Option value="2">Вертикальное видео</Option>
										<Option value="3">Изображения</Option>
										<Option value="4">Трансляция YouTube</Option>
										<Option value="5">Текст</Option>
									</Select>
								</div>
							</Col>
							{Number(lessonsStore.lesson.format) !== 3 && Number(lessonsStore.lesson.format) !== 5 && (
								<Col xs={24} md={12}>
									<div className="CreateLesson__group">
										<Title level={4}>ID видео</Title>
										<Input
											size="large"
											placeholder="Введите ID видео"
											value={lessonsStore.lesson.video}
											onChange={(e) => lessonsStore.onChange("video", e.target.value)}
										/>
									</div>
								</Col>
							)}
							{Number(lessonsStore.lesson.format) === 3 && (
								<Col xs={24}>
									<UploadImage store={lessonsStore} />
									{/* <Upload {...propsImage}>
										<Button icon={<UploadOutlined />} />
									</Upload> */}
								</Col>
							)}
							<Col xs={24}>
								<div className="CreateLesson__group">
									<Editor
										editorState={lessonsStore.lesson.text}
										onEditorStateChange={(e) => lessonsStore.changeEditor(e)}
										toolbar={{
											options: ["inline", "remove", "history", "list"],
											inline: {
												options: [
													"bold",
													"italic",
													"underline",
													"strikethrough",
													"superscript",
													"subscript",
												],
											},
											list: {
												options: ["unordered", "ordered"],
											},
										}}
										editorClassName="editor-block"
									/>
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="CreateLesson__group">
									<Title level={4}>Комментарии</Title>
									<Switch
										className="CreateLesson__group-switch"
										checked={Boolean(Number(lessonsStore.lesson.comments))}
										checkedChildren="Разрешены"
										unCheckedChildren="Запрещены"
										onChange={(e) => {
											if (e === true) {
												lessonsStore.onChange("comments", 1)
											} else {
												lessonsStore.onChange("comments", 0)
											}
										}}
									/>
								</div>
							</Col>
						</Row>

						<div className="CreateLesson__submit">
							<Button size="large" htmlType="submit" type="primary">
								{Number(lessonsStore.idLessons) === 0 ? "Добавить" : "Сохранить"}
							</Button>
						</div>
					</Space>
				</form>
			</Modal>

			<Modal
				title="Выбрать куратора"
				open={usersStore.modalCuratorCourse.status}
				onCancel={usersStore.closeModalCuratorCourse}
				width={320}
				footer={false}
				centered
			>
				<form onSubmit={(e) => usersStore.updateModalCuratorCourse(e)}>
					<Space direction="vertical" size="large" style={{ width: "100%" }}>
						<Select
							style={{ width: "100%" }}
							size="large"
							showSearch
							placeholder={
								usersStore.modalCuratorCourse?.curator?.name
									? usersStore.modalCuratorCourse?.curator?.name
									: "Выбрать куратора"
							}
							optionFilterProp="children"
							defaultValue={usersStore.modalCuratorCourse?.curatorId}
							value={usersStore.modalCuratorCourse?.curatorId}
							onChange={(e) => usersStore.onChangeModalCuratorCourse(e)}
							onSearch={onSearch}
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
						>
							{teamStore.listTeam.map((item) => {
								return (
									<Option key={item.key} value={item?.id}>
										{item?.name}
									</Option>
								)
							})}
						</Select>

						<Button htmlType="submit" style={{ width: "100%" }} type="primary" size="large">
							Сохранить
						</Button>
					</Space>
				</form>
			</Modal>

			<Modal
				title="Изменение доступа к курсу"
				open={store.accessModal.isOpen}
				onOk={store.accessModalOk}
				okText="Подтвердить"
				cancelText="Отменить"
				onCancel={store.accessModalClose}
				width={320}
			>
				<form>
					<Select
						style={{ width: "100%", marginBottom: "30px" }}
						value={store.accessModal.select}
						onChange={(event) => store.accessModalChange(event)}
						size="large"
						placeholder="Статус доступа"
					>
						<Option value="remove">Курс доступен</Option>
						<Option value="add">Курс не доступен</Option>
					</Select>
				</form>
			</Modal>
		</>
	)
}

export default observer(CreateCoursesPage)
