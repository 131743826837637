import { Divider, Table } from "antd"
import { observer } from "mobx-react-lite"
import { useLayoutEffect } from "react"
import { useParams } from "react-router-dom"

import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

const columns = [
	{
		title: "ID",
		dataIndex: "key",
	},
	{
		title: "Название",
		dataIndex: "name",
	},
	{
		title: "Дата регистрации",
		dataIndex: "date_registration",
	},
	{
		title: "Курс",
		dataIndex: "course",
	},
]

export const UserWebinars = observer((second) => {
	const { id } = useParams()
	useLayoutEffect(() => {
		webinarsStore.getAllWebinarsOnUser(id)
	}, [])

	return (
		<>
			<h2>Вебинары</h2>
			<Table
				dataSource={webinarsStore.webinarListOnUserRegistered}
				columns={columns}
				// pagination={}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {webinarsStore.webinarListOnUserRegistered.length}</div>
			<Divider />
		</>
	)
})
