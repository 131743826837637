import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"

import { coursesStore } from "store/Courses/CoursesStore"
import { store } from "store/Promocode/Promocode.store"

import { Form } from "./components"

const CreatePromoCodePage = () => {
	const params = useParams()

	useEffect(() => {
		coursesStore.getAllCourses(0)

		if (Number(params.id) > 0) {
			store.getPromocodeId(Number(params.id))
		}
	}, [])

	if (!params.id) {
		return null
	}

	return (
		<>
			<SEO title={params.id > 0 ? "Редактирование промокода" : "Новый промокод"} />
			<HeaderComponent />
			<div className="container">
				<Form params={params} store={store} listCourses={coursesStore.listCourses} />
			</div>
		</>
	)
}

export default observer(CreatePromoCodePage)
