import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import AuthPage from "../pages/AuthPage/AuthPage"
import PageNotFound from "../pages/NotFound/NotFound"

const RoutesPublic = () => {
	return (
		<>
			<Routes>
				<Route path="/auth" element={<AuthPage />} />
				<Route path="/" element={<Navigate to="/auth" replace />} />
				<Route path="/404" element={<PageNotFound />} />
				<Route path="*" element={<Navigate to="/404" replace />} />
			</Routes>
		</>
	)
}

export default RoutesPublic
