import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { observer } from "mobx-react-lite"

import { lessonsStore as store } from "store/Lessons/LessonsStore"

import dateFormat from "hooks/dateFormat"

import "./Lessons.scss"

import TitlePage from "../TitlePage/TitlePage"

const Lessons = (params) => {
	const { lessons, courseId } = params

	const columns = [
		{
			title: "Название",
			dataIndex: "title",
			key: "title",
			render: (text, record) => (
				<Typography.Link onClick={() => store.openModal(Number(record.id))}>{text}</Typography.Link>
			),
		},
		{
			title: "Дата публикации",
			dataIndex: "date_active",
			key: "date_active",
			render: (text) => dateFormat(text),
		},
		{
			title: "Действия",
			dataIndex: "edit",
			key: "edit",
			width: "25%",
			render(_, edit) {
				return (
					<Space>
						<span>
							<Typography.Link
								style={{ marginRight: 8 }}
								onClick={() => store.openModal(Number(edit.id))}
							>
								<EditOutlined />
							</Typography.Link>
							<Popconfirm
								title="Удалить запись?"
								onConfirm={() => store.deleteLesson(edit.id, courseId)}
								okText="Да"
								cancelText="Нет"
							>
								<Button type="link">
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</span>
					</Space>
				)
			},
		},
	]

	return (
		<>
			<TitlePage
				status={false}
				title="Список лекций"
				buttonText="Добавить лекцию"
				buttonModal={() => store.openModal()}
			/>
			<Table columns={columns} dataSource={lessons} />
		</>
	)
}

export default observer(Lessons)
