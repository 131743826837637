import SEO from "@americanexpress/react-seo"
import { Button, Col, DatePicker, Input, Row, Select, Space } from "antd"
import locale from "antd/es/date-picker/locale/ru_RU"
import { observer } from "mobx-react-lite"
import "moment/locale/ru"
import React, { useEffect } from "react"

import CoursesList from "components/Courses/CoursesList/CoursesList"
import HeaderComponent from "components/Header/Header"
import TitlePage from "components/TitlePage/TitlePage"

import { coursesStore } from "store/Courses/CoursesStore"

const { Option } = Select
const { RangePicker } = DatePicker

const CoursesPage = () => {
	useEffect(() => {
		coursesStore.getAllCourses()
	}, [])

	return (
		<>
			<SEO title="Список курсов" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Список курсов"
					buttonText="Добавить курс"
					buttonLink="/courses/0"
					fileName="courses"
					store={coursesStore}
				/>

				<form
					style={{ marginBottom: "30px" }}
					onSubmit={(event) => {
						event.preventDefault()
						coursesStore.getFilterCourses()
					}}
				>
					<Row gutter={[15, 15]}>
						<Col span={3}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<span>Поиск по id</span>
								<Input
									placeholder="Введите id курса"
									allowClear
									onChange={(event) => coursesStore.onChangeFilter("id", Number(event.target.value))}
								/>
							</Space>
						</Col>

						<Col span={3}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<span>Статус курса</span>
								<Select
									style={{
										width: "100%",
									}}
									defaultValue={2}
									value={coursesStore.filter.is_active}
									onChange={(event) => coursesStore.onChangeFilter("is_active", event)}
								>
									<Option key={0} value={2}>
										Все
									</Option>
									<Option key={1} value={1}>
										Активные
									</Option>
									<Option key={2} value={0}>
										Не активные
									</Option>
								</Select>
							</Space>
						</Col>

						<Col span={5}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<span>Дата создания курса</span>
								<RangePicker
									style={{
										width: "100%",
									}}
									locale={locale}
									format="DD.MM.YYYY"
									onChange={(event) => coursesStore.onChangeFilterDate("create", event)}
								/>
							</Space>
						</Col>

						<Col span={5}>
							<Space direction="vertical" style={{ width: "100%" }}>
								<span>Дата старта курса</span>
								<RangePicker
									style={{
										width: "100%",
									}}
									locale={locale}
									format="DD.MM.YYYY"
									onChange={(event) => coursesStore.onChangeFilterDate("start", event)}
								/>
							</Space>
						</Col>

						<Col span={3}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
								}}
							>
								<span style={{ visibility: "hidden" }}>Применить</span>
								<Button type="primary" htmlType="submit" block>
									Применить
								</Button>
							</Space>
						</Col>
					</Row>
				</form>

				<CoursesList store={coursesStore} />
			</div>
		</>
	)
}

export default observer(CoursesPage)
