import SEO from "@americanexpress/react-seo"
import { Button, Select, Space } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import HeaderComponent from "components/Header/Header"
import TitlePage from "components/TitlePage/TitlePage"
import WebinarsList from "components/WebinarsList/WebinarsList"

import { coursesStore } from "store/Courses/CoursesStore"
import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

const { Option } = Select
const WebinarsPage = () => {
	useEffect(() => {
		coursesStore.getAllCourses(9999)
		webinarsStore.getAllWebinars()
	}, [])
	return (
		<>
			<SEO title="Список вебинаров" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Список вебинаров"
					buttonText="Добавить вебинар"
					buttonLink="/webinars/0"
					fileName="webinars"
					listFull={webinarsStore.listFullWebinar}
					getList={webinarsStore.getListWebinars}
					store={webinarsStore}
				/>
				<form
					className="UsersPageForm"
					onSubmit={(e) => {
						e.preventDefault()
						webinarsStore.getSortWebinars()
					}}
				>
					<Space align="end" size={24}>
						<Space direction="vertical">
							<span>Сортировка</span>
							<Select
								style={{
									width: 160,
								}}
								defaultValue={1}
								value={webinarsStore.sort.sort_id}
								onChange={(e) => webinarsStore.onChangeSort(e)}
							>
								<Option value={0}>По возрастанию</Option>
								<Option value={1}>По убыванию</Option>
							</Select>
						</Space>

						<Button type="primary" htmlType="submit">
							Применить
						</Button>
					</Space>
				</form>
				<WebinarsList dataSource={webinarsStore.listWebinars} items={webinarsStore.total} />
			</div>
		</>
	)
}

export default observer(WebinarsPage)
