// Import Framework
import { Col, Divider, Row } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"

import { usersStore } from "store/Users/UsersStore"

// Import Hooks
import dataFormat from "hooks/dateFormat"

export const UserEditor = observer(() => {
	const { date_reg, updated_by, date_update } = usersStore.newUser

	useEffect(() => {
		if (usersStore.newUser && updated_by) {
			usersStore.getUserEditor(updated_by, "edit")
		}
	}, [updated_by])

	return (
		<div style={{ paddingBottom: "30px" }}>
			<Divider />
			<Row gutter={[24, 24]} justify="center">
				{date_reg && (
					<Col xs={24} sm={12} md={8}>
						<div>
							<strong>Дата создания:</strong> {dataFormat(date_reg)}
						</div>
					</Col>
				)}

				{usersStore.editUserStatus === true && (
					<Col xs={24} sm={12} md={8}>
						<div>
							<strong>Дата изменния:</strong> {dataFormat(date_update)}
						</div>
						<div>
							<strong>Кем было изменено:</strong> {usersStore.editItemUser.name}
						</div>
					</Col>
				)}
			</Row>
		</div>
	)
})
