// Import Components
import SEO from "@americanexpress/react-seo"
// Import Store
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"
import { UserEditor, UserForm, UserPayments, UserWebinars } from "components/Users"

import { usersStore } from "store/Users/UsersStore"

// Import Style
import "./CreateUsersPage.scss"

const CreateUsersPage = () => {
	const params = useParams()
	const userSurname = usersStore?.newUser?.surname ? usersStore.newUser.surname : ""
	const userName = usersStore?.newUser?.name
	const userSurnameName = userSurname ? `${userSurname} ${userName}` : userName
	return (
		<>
			<SEO title={params.id > 0 ? userSurnameName : "Новый пользователь"} />
			<HeaderComponent />
			<div className="container">
				<UserForm />
				{params.id > 0 && (
					<>
						<UserPayments />
						<UserEditor />
						<UserWebinars />
					</>
				)}
			</div>
		</>
	)
}

export default observer(CreateUsersPage)
