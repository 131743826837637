// Import Framework
import { Button, Col, Divider, Input, InputNumber, Modal, Row, Select, Typography } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"

import PaymentsList from "components/Payments/PaymentsList"

import { coursesStore } from "store/Courses/CoursesStore"
import { paymentStore } from "store/PaymentStore/PaymentStore"
import { usersStore } from "store/Users/UsersStore"

// import dateFormat from "hooks/dateFormat"

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

export const UserPayments = observer(() => {
	const { id } = usersStore.newUser
	useEffect(() => {
		if (id > 0) {
			paymentStore.getUserPayments(id, 99999, 0)
			usersStore.payerID = id
		}
		coursesStore.getAllCourses(50)
	}, [id])

	return (
		<div className="user-payments">
			<Divider />
			<Row justify="center">
				<Col xs={24} md={12}>
					<h2>Оплаты</h2>
				</Col>
				<Col xs={24} md={12}>
					<div className="user-payments__buttons">
						<Button type="primary" onClick={(_) => usersStore.setNewPaymentModal()}>
							Добавить новую
						</Button>
					</div>
				</Col>
				<Col xs={24}>
					<PaymentsList
						dataSource={paymentStore.listPayments}
						items={paymentStore.total}
						hideHeader={true}
						hidePayer={true}
					/>
				</Col>
			</Row>
			<Modal
				open={usersStore.newPaymentModalVisible}
				okText="Добавить"
				cancelText="Отменить"
				onCancel={(_) => usersStore.setNewPaymentModal()}
				onOk={(_) => usersStore.makeNewPayment()}
			>
				<Row className="CreateUsers__row" gutter={[20, 20]}>
					<Col xs={24}>
						<Row gutter={[15, 24]} justify="space-between">
							<Col xs={24} md={20}>
								<Title level={3}>Добавить новую оплату</Title>
							</Col>
						</Row>
					</Col>
					<Col xs={24}>
						<Title level={5}>Курс</Title>
						<Select
							placeholder="Выберите курс"
							value={usersStore.newPayment.courseID}
							onChange={(e) => usersStore.onChangeNewPayment("courseID", e)}
						>
							{coursesStore.listCourses.map((course) => {
								return (
									<Option key={course.id} value={course.id}>
										(ID: {course.id}) {course.title}
										<br />
										Формат - {course?.city}
										<br />
										Старт - {course?.date_start?.split(" ")[0]}
									</Option>
								)
							})}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<Title level={5}>Сумма</Title>
						<InputNumber
							controls={false}
							value={usersStore.newPayment.amount}
							onChange={(e) => usersStore.onChangeNewPayment("amount", e)}
						/>
					</Col>
					<Col xs={24} md={12}>
						<Title level={5}>Система оплаты</Title>
						<Select
							placeholder="Система оплаты"
							value={usersStore.newPayment.system}
							onChange={(e) => usersStore.onChangeNewPayment("system", e)}
						>
							<Option value="1">Сбербанк</Option>
							<Option value="2">Сбербанк рассрочка</Option>
							<Option value="3">Рассрочка МК</Option>
							<Option value="4">Карта/банк</Option>
							<Option value="5">Рассрочка Тинькофф</Option>
						</Select>
					</Col>
					<Col xs={24}>
						<p>Если сумма оплаты равна 0, то система оплаты обязательно должна быть "Карта/банк"!</p>
					</Col>
					<Col xs={24}>
						<Title level={5}>Комментарий к оплате</Title>
						<TextArea
							value={usersStore.newPayment.comment}
							onChange={(e) => usersStore.onChangeNewPayment("comment", e.target.value)}
						/>
					</Col>
					<Col xs={24}>
						<Title level={5}>Внешний ID</Title>
						<p>Если не знаешь, что писать - оставь пустым</p>
						<Input
							value={usersStore.newPayment.ext_id}
							onChange={(e) => usersStore.onChangeNewPayment("ext_id", e.target.value)}
						/>
					</Col>
				</Row>
			</Modal>
		</div>
	)
})
