import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"

import CategoriesList from "components/Categories/CategoriesList/CategoriesList"
import HeaderComponent from "components/Header/Header"
import TitlePage from "components/TitlePage/TitlePage"

import { categoriesStore } from "store/Categories/CategoriesStore"

const CategoriesPage = observer(() => {
	useEffect(() => {
		categoriesStore.getAllCategories()
	}, [])
	return (
		<>
			<SEO title="Список категорий" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Список категорий"
					buttonText="Добавить категорию"
					buttonLink="/categories/0"
					fileName="categories"
				/>
				<CategoriesList dataSource={categoriesStore.listCategories} items={categoriesStore.total} />
			</div>
		</>
	)
})

export default CategoriesPage
