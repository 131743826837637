import SEO from "@americanexpress/react-seo"
import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Input, Row, Space, Switch, Typography, Upload, message } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"

import { teamStore } from "store/Team/TeamStore"
import { usersStore } from "store/Users/UsersStore"

import "./CreateTeamPage.scss"

import dateFormat from "../../hooks/dateFormat"

const { Title } = Typography
const { TextArea } = Input
const CreateTeamPage = () => {
	const params = useParams()
	const { created_by, updated_by, date_create, date_update } = teamStore.team
	useEffect(() => {
		if (params.id > 0) {
			teamStore.getTeam(Number(params.id))
		} else {
			teamStore.setTeam()
		}
		if (created_by) {
			usersStore.getUserEditor(created_by, "create")
		}
		if (updated_by) {
			usersStore.getUserEditor(updated_by, "edit")
		}
	}, [params.id, created_by, updated_by])

	const uploadProps = {
		fileList: teamStore.team.photo,
		accept: "image/jpeg, image/png, image/gif",
		maxCount: 1,
		listType: "picture-card",
		showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
		onRemove: (file) => {
			teamStore.team.photo = []
		},
		beforeUpload: (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif"

			if (!isJpgOrPng) {
				message.error("Можно загружать только JPG/PNG/GIF файл!")
			}
			teamStore.team.photo = [file]
			return false
		},
	}

	return (
		<>
			<SEO title={params.id > 0 ? "Редактирование члена команды" : "Новый член команды"} />
			<HeaderComponent />
			<div className="container">
				<form onSubmit={(e) => teamStore.editTeam(e)} className="CreateTeam">
					<Row className="CreateTeam__row" gutter={[15, 24]}>
						<Col xs={24} lg={18} className="CreateTeam__title">
							<Row gutter={[15, 24]} justify="space-between">
								<Col xs={24} md={20}>
									<Title>
										{params.id > 0 ? "Редактирование члена команды" : "Новый член команды"}
									</Title>
								</Col>

								<Col xs={24} md={4} className="CreateTeam__title-block">
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										className="CreateTeam__title-button"
									>
										{params.id > 0 ? "Сохранить" : "Создать"}
									</Button>
								</Col>
							</Row>
						</Col>

						<Col xs={24} lg={18}>
							<Row gutter={[15, 24]}>
								<Col xs={24} md={18} lg={19}>
									<Space direction="vertical">
										<Title level={4}>Имя</Title>
										<Input
											size="large"
											placeholder="Введите имя"
											value={teamStore.team.name}
											onChange={(e) => teamStore.onChange("name", e.target.value)}
										/>
									</Space>
								</Col>

								<Col xs={24} md={6} lg={5} className="CreateTeam__switch">
									<Space direction="vertical">
										<Title level={4}>Статус активности</Title>
										<Switch
											className="CreateTeam__switch-switch"
											checked={Boolean(Number(teamStore.team.status))}
											checkedChildren="Активен"
											unCheckedChildren="Не активен"
											onChange={(e) => {
												if (e === true) {
													teamStore.onChange("status", 1)
												} else {
													teamStore.onChange("status", 0)
												}
											}}
										/>
									</Space>
								</Col>
							</Row>
						</Col>

						<Col xs={24} lg={18}>
							<Row gutter={[15, 24]}>
								<Col xs={24} md={8} lg={6}>
									<Space direction="vertical">
										<Title level={4}>Фотография</Title>
										<Upload {...uploadProps}>
											<Button icon={<UploadOutlined />} />
										</Upload>
									</Space>
								</Col>

								<Col xs={24} lg={4}>
									<Space direction="vertical" className="CreateTeam__post">
										<Title level={4}>Должность</Title>
										<Input
											value={teamStore.team.post}
											onChange={(e) => {
												teamStore.onChange("post", e.target.value)
											}}
										/>
									</Space>
								</Col>

								<Col xs={24} lg={4}>
									<Space direction="vertical" className="CreateTeam__post">
										<Title level={4}>ID пользователя</Title>
										<Input
											value={teamStore.team.user_id}
											onChange={(e) => {
												teamStore.onChange("user_id", e.target.value)
											}}
										/>
									</Space>
								</Col>

								<Col xs={24} lg={4}>
									<Space direction="vertical" className="CreateTeam__position">
										<Title level={4}>Сортировка</Title>
										<Input
											value={teamStore.team.position}
											onChange={(e) => {
												teamStore.onChange("position", e.target.value)
											}}
										/>
									</Space>
								</Col>

								<Col xs={24} lg={6}>
									<Space direction="vertical" className="CreateTeam__post">
										<Title level={4}>Ссылка на соц. сеть</Title>
										<Input
											value={teamStore.team.contact}
											onChange={(e) => {
												teamStore.onChange("contact", e.target.value)
											}}
										/>
									</Space>
								</Col>

								<Col xs={24}>
									<Space direction="vertical" className="CreateTeam__description">
										<Title level={4}>Описание</Title>
										<TextArea
											autoSize={{
												minRows: 5,
											}}
											value={teamStore.team.description}
											onChange={(e) => teamStore.onChange("description", e.target.value)}
										/>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
				</form>
				<div style={{ paddingBottom: "30px" }}>
					<Divider />
					<Row gutter={[24, 24]} justify="center">
						{usersStore.createUserStatus === true && (
							<Col xs={24} sm={12} md={8}>
								<div>
									<strong>Дата создания:</strong> {dateFormat(date_create)}
								</div>
								<div>
									<strong>Кем было создано:</strong> {usersStore.createItemUser.name}
								</div>
							</Col>
						)}

						{usersStore.editUserStatus === true && (
							<Col xs={24} sm={12} md={8}>
								<div>
									<strong>Дата изменния:</strong> {dateFormat(date_update)}
								</div>
								<div>
									<strong>Кем было изменено:</strong> {usersStore.editItemUser.name}
								</div>
							</Col>
						)}
					</Row>
				</div>
			</div>
		</>
	)
}

export default observer(CreateTeamPage)
