import { action, makeAutoObservable } from "mobx"
import moment from "moment"

import axios from "../../axios"
import dateFormat from "../../hooks/dateFormat"

class PaymentStore {
	listPayments = []
	total = null
	limit = 15
	offset = 0
	pageSize = 15
	listFull = []
	filter = {
		sortId: 0, // 0 - ASC, 1 - DESC
		courses: [], // Array id courses
		payed: null, // null - All payed, 0 - Not paid, 1 - Payed
		status: 0,
		date_start: "",
		date_end: "",
	}

	statusCSV = false

	payments = {
		count: null,
		courses: [],
		data: [],
		pageSize: 15,
		limit: 15,
		offset: 0,
		filter: {
			id: null,
			sortId: 0, // 0 - ASC, 1 - DESC
			payer_name: null,
			courses: [], // Array id courses
			notBuyCourses: [], // Array id courses
			payed: 2, // null - All payed, 0 - Not paid, 1 - Payed
			status: 0,
			date_start: "",
			date_end: "",
		},
	}

	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			if (this.filter.status === 0) {
				this.offset = this.getAllPayments(null, this.newItems, offset)
			} else {
				this.offset = this.getFilterPayments(this.newItems, offset)
			}
		},
	}

	paginationPayments = {
		pageSize: this.payments.pageSize,
		total: this.payments.count,
		onChange: (current) => {
			const offset = Number(current) * Number(this.payments.pageSize) - Number(this.payments.pageSize)
			const newItems = this.payments.pageSize
			if (this.payments.filter.status === 0) {
				this.payments.offset = this.getListPayments(null, newItems, offset)
			} else {
				this.payments.offset = this.getListFilterPayments(newItems, offset)
			}
		},
	}

	constructor() {
		makeAutoObservable(this)
	}

	editStatusUser = (event) => {
		event.preventDefault()
	}

	onChangeFilter = (name, value) => {
		this.payments.filter[name] = value
	}

	onChangeDate = (date) => {
		this.payments.filter.date_start = Math.trunc(moment(date[0]) / 1000)
		this.payments.filter.date_end = Math.trunc(moment(date[1]) / 1000)
	}

	listFullPayments = (payments) => {
		this.listFull = payments.map((pay) => {
			if (pay?.system === "1") {
				pay.system = "Сбербанк"
			} else if (pay?.system === "2") {
				pay.system = "Сбербанк рассрочка"
			} else if (pay?.system === "3") {
				pay.system = "Рассрочка MK"
			} else if (pay?.system === "4") {
				pay.system = "Карта"
			} else if (pay?.system === "5") {
				pay.system = "Рассрочка Тинькофф"
			}

			return {
				"ID оплаты": pay?.id ? pay.id : "",
				"Название курса": pay?.course ? `${pay.course?.title} ${pay.course?.city}` : "",
				"Дата старта курса": pay?.course?.date_start ? dateFormat(pay.course.date_start) : "",
				"Дата оплата": pay?.date_payed ? dateFormat(pay.date_payed) : "",
				Имя: pay?.payer?.name ? pay.payer.name.trim() : "",
				Фамилия: pay?.payer?.surname ? pay.payer.surname.trim() : "",
				Email: pay?.payer?.email ? pay.payer.email.trim() : "",
				Телефон: pay?.payer?.phone ? pay.payer.phone.trim() : "",
				Страна: pay?.payer?.country ? pay.payer.country.trim() : "",
				Город: pay?.payer?.city ? pay.payer.city.trim() : "",
				"Сумма оплаты": pay?.amount
					? new Intl.NumberFormat("ru-RU", {
							style: "currency",
							notation: "standard",
							maximumFractionDigits: 0,
							currency: "RUB",
					  }).format(pay.amount)
					: "",
				"Система оплаты": pay?.system ? pay.system : "",
			}
		})

		this.statusCSV = true
		setTimeout(
			action(() => {
				this.statusCSV = false
			}),
			1000,
		)
	}

	getAllPayments = (courses = null, limit = this.limit, offset = this.offset) => {
		let params
		if (courses !== null) {
			params = { courses: JSON.stringify([Number(courses)]), payed: 1, limit: limit, offset: offset }
		} else {
			params = { limit: limit, offset: offset }
		}
		axios
			.get("/payments/payments", {
				params: params,
			})
			.then(
				action((success) => {
					const [payments, count] = success.data
					if (count) {
						this.total = count[0].count
						this.pagination.total = this.total
						this.listPayments = payments.map((payment) => {
							return {
								key: payment.id,
								...payment,
								date_create: payment?.date_create ? dateFormat(payment?.date_create) : null,
								date_payed: payment?.date_payed ? dateFormat(payment?.date_payed) : null,
								date_update: payment?.date_update ? dateFormat(payment?.date_update) : null,
							}
						})
					} else {
						this.total = 0
						this.pagination.total = this.total
						this.listPayments = []
					}
				}),
			)
	}

	getListPayments = (courses = null, limit = this.payments.limit, offset = this.payments.offset) => {
		let params
		if (courses !== null) {
			params = { courses: JSON.stringify([Number(courses)]), payed: 1, limit: limit, offset: offset }
		} else {
			params = { limit: limit, offset: offset }
		}
		axios
			.get("/payments/courses-payments", { params: params })
			.then(
				action((success) => {
					const { count, courses, data } = success.data

					this.payments.filter.status = 1
					if (count) {
						this.payments.courses = courses
						this.payments.count = count
						this.paginationPayments.total = this.payments.count
						this.payments.data = data.map((item) => {
							return {
								key: item.id,
								...item,
								date_create: item.date_create && dateFormat(item.date_create),
								date_payed: item.date_payed ? dateFormat(item.date_payed) : <>Не оплачено</>,
								date_update: item.date_update && dateFormat(item.date_update),
								// payment: item.amount - item.discount,
							}
						})
					} else {
						this.payments.count = 0
						this.paginationPayments.total = this.payments.count
						this.payments.data = []
					}
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	getListFilterPayments = (limit = this.payments.limit, offset = this.payments.offset) => {
		axios
			.get("/payments/courses-payments", {
				params: {
					limit: limit,
					offset: offset,
					payment_id: this.payments.filter.id,
					payed: this.payments.filter.payed === 2 ? null : this.payments.filter.payed,
					payer_name: this.payments.filter.payer_name,
					courses: JSON.stringify(this.payments.filter.courses),
					notBuyCourses: JSON.stringify(this.payments.filter.notBuyCourses),
					sort_id: this.payments.filter.sortId,
					date_start: this.payments.filter.date_start,
					date_end: this.payments.filter.date_end,
				},
			})
			.then(
				action((success) => {
					const { data, courses, count } = success.data

					this.payments.filter.status = 1
					if (count) {
						this.payments.courses = courses
						this.payments.count = count
						this.paginationPayments.total = this.payments.count
						this.payments.data = data.map((item) => {
							return {
								key: item.id,
								...item,
								date_create: item.date_create && dateFormat(item.date_create),
								date_payed: item.date_payed ? dateFormat(item.date_payed) : <>Не оплачено</>,
								date_update: item.date_update && dateFormat(item.date_update),
								payment: item.amount - item.discount,
							}
						})
					} else {
						this.payments.count = 0
						this.paginationPayments.total = this.payments.count
						this.payments.data = []
					}
				}),
			)
	}

	getList = () => {
		axios
			.get("/payments/courses-payments", {
				params: {
					limit: this.payments.count,
					payed: this.filter.payed,
					payment_id: this.payments.filter.id,
					payer_name: this.payments.filter.payer_name,
					courses: JSON.stringify(this.payments.filter.courses),
					notBuyCourses: JSON.stringify(this.payments.filter.notBuyCourses),
					sort_id: this.payments.filter.sortId,
					date_start: this.payments.filter.date_start,
					date_end: this.payments.filter.date_end,
				},
			})
			.then(
				action((success) => {
					const { data } = success.data
					if (data?.length > 0) {
						this.listFullPayments(data)
					} else {
						return (this.listFull = [])
					}
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error}`)
			})
	}

	getFilterPayments = (limit = this.limit, offset = this.offset) => {
		axios
			.get("/payments/payments", {
				params: {
					limit: limit,
					offset: offset,
					payed: this.filter.payed,
					courses: JSON.stringify(this.filter.courses),
					sort_id: this.filter.sortId,
					date_start: this.filter.date_start,
					date_end: this.filter.date_end,
				},
			})
			.then(
				action((success) => {
					this.filter.status = 1
					const [payments, count] = success.data
					if (count) {
						this.total = count[0].count
						this.pagination.total = this.total
						this.listPayments = payments.map((payment) => {
							return {
								key: payment.id,
								...payment,
								date_create: payment?.date_create ? dateFormat(payment?.date_create) : null,
								date_payed: payment?.date_payed ? dateFormat(payment?.date_payed) : null,
								date_update: payment?.date_update ? dateFormat(payment?.date_update) : null,
							}
						})
					} else {
						this.total = 0
						this.pagination.total = this.total
						this.listPayments = []
					}
				}),
			)
	}

	getUserPayments = (userID, limit = this.limit, offset = this.offset) => {
		axios
			.get("/payments/user", {
				params: {
					user: userID,
					limit: limit,
					offset: offset,
				},
			})
			.then(
				action((success) => {
					const payments = success.data.data
					const count = success.data.data.length
					if (count) {
						this.total = count
						this.pagination.total = count
						this.listPayments = payments.reverse().map((payment) => {
							return {
								key: payment.id,
								...payment,
								date_create: payment?.date_create ? dateFormat(payment?.date_create) : null,
								date_payed: payment?.date_payed ? dateFormat(payment?.date_payed) : null,
								date_update: payment?.date_update ? dateFormat(payment?.date_update) : null,
							}
						})
					} else {
						this.total = 0
						this.pagination.total = this.total
						this.listPayments = []
					}
				}),
			)
	}

	setDate(d) {
		const dateFormat = "YYYY-MM-DD"
		d = moment.unix(d).format(dateFormat)
		return moment(d, dateFormat)
	}
	actualizeChecks = async () => {
		try {
			const result = await axios.get("/payments/fiskal/?all=month")
			return { result }
		} catch (error) {
			return { error }
		}
	}
}

export const paymentStore = new PaymentStore()
