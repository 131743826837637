import { message } from "antd"
import { action, makeAutoObservable, runInAction, toJS } from "mobx"
import moment from "moment"
import "moment/locale/ru"

import dateFormat from "hooks/dateFormat"

import axios from "../../axios"
import config from "../../config.json"

class CoursesStore {
	constructor() {
		makeAutoObservable(this)
	}

	listCourses = []
	course = {}
	total = ""
	limit = 15
	offset = 0
	pageSize = 15
	listFull = []
	statusCSV = false

	filter = {
		status: false,
		id: null,
		is_active: 2,
		date_create_start: null,
		date_create_end: null,
		date_start_start: null,
		date_start_end: null,
	}

	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			if (this.filter.status === true) {
				this.offset = this.getFilterCourses(this.newItems, offset)
			} else {
				this.offset = this.getAllCourses(this.newItems, offset)
			}
		},
	}

	initStates = {
		prices: {
			draggedFrom: null,
			draggedTo: null,
			isDragging: false,
			originalOrder: [],
			updatedOrder: [],
		},
		in_numbers: {
			draggedFrom: null,
			draggedTo: null,
			isDragging: false,
			originalOrder: [],
			updatedOrder: [],
		},
	}

	accessModal = {
		isOpen: false,
		select: null,
		user_id: null,
		course_id: null,
	}

	userList = []
	userListCSV = []

	getUserListCSV() {
		const userList = this.userList.reduce((userList, user) => {
			const { key, id, name, surname, phone, email, lastLesson } = user

			const CSVUser = {
				key,
				id,
				name,
				surname,
				phone,
				email,
				lastLesson: lastLesson?.title,
			}
			userList.push(CSVUser)
			return userList
		}, [])
		this.userListCSV = userList
	}

	onChange(name, value) {
		this.course[name] = value
	}

	onChangePrice(name, index, value) {
		this.course.prices[index][name] = value
	}

	onChangeInNumbers(name, index, value) {
		this.course.in_numbers[index][name] = value
	}

	onChangeFilter = (name, value) => {
		this.filter[name] = value
	}

	onChangeFilterDate = (type, date) => {
		if (type === "create") {
			this.filter.date_create_start = date !== null ? Math.trunc(moment(date[0]) / 1000) : null
			this.filter.date_create_end = date !== null ? Math.trunc(moment(date[1]) / 1000) : null
		}

		if (type === "start") {
			this.filter.date_start_start = date !== null ? Math.trunc(moment(date[0]) / 1000) : null
			this.filter.date_start_end = date !== null ? Math.trunc(moment(date[1]) / 1000) : null
		}
	}

	listFullCourses = (courses) => {
		this.listFull = courses.map((course) => {
			if (course.category === 1) {
				course.category = "Курс"
			} else if (course.category === 3) {
				course.category = "Марафон"
			}

			if (course.status === true) {
				course.status = "Активен"
			} else if (course.status === false) {
				course.status = "Не активен"
			}

			return {
				"ID курса": course.id ? course.id : "",
				Название: course.title ? course.title : "",
				"Место проведения": course?.city ? course.city : "",
				Тип: course.category,
				"Дата старта": course?.date_start ? dateFormat(course.date_start) : "",
				"Кол-во покупок": course.purchasesNumber,
				"Статус курса": course.status,
			}
		})

		this.statusCSV = true
		setTimeout(
			action(() => {
				this.statusCSV = false
			}),
			1000,
		)
	}

	getFilterCourses = (limit = this.limit, offset = this.offset, sort_id = 1) => {
		let params = {
			limit: limit,
			offset: offset,
			sort_id: sort_id,
		}

		if (this.filter.id !== null) {
			params.course_id = this.filter.id
		}

		if (this.filter.id === 0) {
			this.filter.id = null
			params.course_id = this.filter.id
		}

		if (this.filter.is_active !== 2) {
			params.is_active = this.filter.is_active
		}

		if (this.filter.date_create_start !== null && this.filter.date_create_end !== null) {
			params.date_create_start = this.filter.date_create_start
			params.date_create_end = this.filter.date_create_end
		}

		if (this.filter.date_start_start !== null && this.filter.date_start_end !== null) {
			params.date_start_start = this.filter.date_start_start
			params.date_start_end = this.filter.date_start_end
		}

		// TODO: Пагинацию сделать с фильтром

		axios
			.get("/courses/courses", {
				params: params,
			})
			.then((success) => {
				const [courses, count] = success.data
				this.filter.status = true
				this.total = count.count
				this.pagination.total = this.total

				this.listCourses = courses.map((course) => {
					return {
						key: course.id,
						...course,
						date_create: course?.date_create ? dateFormat(course?.date_create) : null,
						date_active: course?.date_active ? dateFormat(course?.date_active) : null,
						date_start: course?.date_start ? dateFormat(course?.date_start) : null,
						date_update: course?.date_update ? dateFormat(course?.date_update) : null,
					}
				})
			})
	}

	getAllCourses(limit = this.limit, offset = this.offset, sort_id = 1) {
		axios
			.get("/courses/courses", {
				params: {
					limit: limit,
					offset: offset,
					sort_id: sort_id,
				},
			})
			.then(
				action((success) => {
					const [courses, count] = success.data

					this.total = count.count
					this.pagination.total = this.total

					this.listCourses = courses.map((course) => {
						return {
							key: course.id,
							...course,
							date_create: course?.date_create ? dateFormat(course?.date_create) : null,
							date_active: course?.date_active ? dateFormat(course?.date_active) : null,
							date_start: course?.date_start ? dateFormat(course?.date_start) : null,
							date_update: course?.date_update ? dateFormat(course?.date_update) : null,
						}
					})
				}),
			)
	}

	cloneCourse = (event, id) => {
		event.preventDefault()

		axios.post(`/courses/clone/${id}`).then((success) => {
			window.location.href = `/courses/${success.data}`
		})
	}

	getList = () => {
		axios
			.get("/courses/courses", {
				params: {
					limit: this.total,
				},
			})
			.then(
				action((success) => {
					const [courses] = success.data
					if (courses?.length > 0) {
						this.listFullCourses(courses)
					} else {
						return (this.listFull = [])
					}
				}),
			)
	}

	setCourse() {
		this.course = {
			id: 0,
			title: "",
			status: 0,
			category: "",
			photo: null,
			description: "",
			full_description: "",
			prices: [],
			date_start: "",
			date_active: "",
			course_type: "",
			desc_heading: "",
			course_video: "",
			team: [],
			related_courses: [],
			city: "",
			in_numbers: [],
			site_course_url: "",
			commentary: "",
		}
	}

	getCourse(id) {
		axios
			.get(`/courses/course/${id}`)
			.then(
				action((success) => {
					this.course = success.data
					if (this.course.prices !== null) {
						this.course.prices = JSON.parse(success.data.prices)
						if (!(this.course.prices instanceof Array)) {
							this.course.prices = [this.course.prices]
						}
					} else {
						this.course.prices = []
					}

					if (this.course.in_numbers !== null) {
						this.course.in_numbers = JSON.parse(success.data.in_numbers)
						if (!(this.course.in_numbers instanceof Array)) {
							this.course.in_numbers = [this.course.in_numbers]
						}
					} else {
						this.course.in_numbers = []
					}

					if (this.course.team !== null) {
						this.course.team = JSON.parse(success.data.team)
					} else {
						this.course.team = []
					}

					if (this.course.related_courses !== null) {
						this.course.related_courses = JSON.parse(success.data.related_courses).map(String)
					} else {
						this.course.related_courses = []
					}

					if (this.course.photo !== null && success.data.photo !== "") {
						this.course.photo = [
							{
								status: "done",
								url: config.apiUrl + "" + success.data.photo,
							},
						]
					} else {
						this.course.photo = []
					}

					if (this.course.guide_file !== null && Boolean(success.data.guide_file)) {
						this.course.guide_file = [
							{
								status: "done",
								url: config.apiUrl + "" + success.data.guide_file.path_name,
								name: success.data.guide_file.original_name,
							},
						]
					} else {
						this.course.guide_file = []
					}
				}),
			)
			.catch((error) => {
				if (error.request.status === 400) {
					window.location.href = "/"
				}
			})
	}

	editCourse(e) {
		e.preventDefault()

		let course = new FormData()
		let url

		if (this.course?.id > 0) {
			url = `edit/${this.course?.id}`
		} else {
			url = "new"
		}

		if (!this.course?.title) return message.error("Не заполнено название.")
		if (!this.course?.category) return message.error("Не указана категория")

		if (this.course?.photo) {
			if (this?.course.photo[0] && !this?.course?.photo[0]?.url) {
				course.append("photo", this?.course?.photo[0])
			} else if (!this.course?.photo[0]) {
				course.append("photo", [])
			}
		}
		if (this.course?.guide_file) {
			if (this?.course.guide_file[0] && !this?.course?.guide_file[0]?.url) {
				course.append("guide_file", this?.course?.guide_file[0])
			} else if (!this.course?.guide_file[0]) {
				// Удаляем файл
				course.append("guide_file", [])
			}
		}

		if (this.course?.team) {
			this.course.team = this.course?.team?.map((numStr) => parseInt(numStr))
		}

		if (this.course?.related_courses) {
			this.course.related_courses = this.course?.related_courses?.map((numStr) => parseInt(numStr))
		}

		this.course.title && course.append("title", this.course.title)
		course.append("status", Number(this.course.status))

		// почему то до этого исправлял на проверку Boolean
		typeof this.course.category === "number" && course.append("category", this.course.category)
		this.course.description && course.append("description", this.course.description)
		this.course.full_description && course.append("full_description", this.course.full_description)
		this.course.prices && course.append("prices", JSON.stringify(this.course.prices))
		this.course.date_start && course.append("date_start", this.course.date_start)
		this.course.date_active && course.append("date_active", this.course.date_active)
		this.course.course_type && course.append("course_type", this.course.course_type)
		this.course.commentary && course.append("commentary", this.course.commentary)
		this.course.desc_heading && course.append("desc_heading", this.course.desc_heading)
		this.course.site_course_url && course.append("site_course_url", this.course.site_course_url)
		this.course.course_video && course.append("course_video", this.course.course_video)
		this.course.team?.length > 0 && course.append("team", JSON.stringify(this.course.team))
		this.course.related_courses?.length > 0 &&
			course.append("related_courses", JSON.stringify(this.course.related_courses))
		this.course.city && course.append("city", this.course.city)
		this.course.in_numbers?.length > 0 && course.append("in_numbers", JSON.stringify(this.course.in_numbers))

		axios
			.post(`/courses/${url}`, course)
			.then(
				action((success) => {
					message.success("Успешно")
					document.location.href = "/courses"
					// redirect("/courses")
				}),
			)
			.catch((error) => {
				const errorMessage = error.response.data.error
				message.error(`Ошибка ${errorMessage}`)
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	onDragStart = (e, name) => {
		const initialPosition = Number(e.currentTarget.dataset.position)
		this.initStates[name] = {
			...this.initStates[name],
			draggedFrom: initialPosition,
			originalOrder: this.course[name],
		}

		e.dataTransfer.setData("text/html", "")
	}

	onDragOver = (e, name) => {
		e.preventDefault()
		let newList = this.initStates[name].originalOrder
		const draggedFrom = this.initStates[name].draggedFrom
		const draggedTo = Number(e.currentTarget.dataset.position)
		const itemDragged = newList[draggedFrom]

		const remainingItems = newList.filter((item, index) => index !== draggedFrom)

		newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)]

		if (draggedTo !== this.initStates[name].draggedTo) {
			this.initStates[name] = {
				...this.initStates[name],
				updatedOrder: newList,
				draggedTo: draggedTo,
			}
		}
	}

	onDrop = (name) => {
		this.initStates[name] = {
			...this.initStates[name],
			draggedFrom: null,
			draggedTo: null,
			isDragging: false,
		}

		this.initStates[name].updatedOrder.map((item, index) => (item.position = index + 1))
		this.course[name] = this.initStates[name].updatedOrder
	}

	onDragLeave = (e, name) => {
		this.initStates[name] = {
			...this.initStates[name],
			draggedTo: null,
		}
	}

	setDate(d) {
		const dateFormat = "YYYY-MM-DD"
		d = moment.unix(d).format(dateFormat)
		return moment(d, dateFormat)
	}

	onChangeDate(name, value) {
		this.course[name] = Math.trunc(moment(value) / 1000)
	}

	getCoursesReg = (id) => {
		axios
			.get(`/courses/regs/${id}`)
			.then((success) => {
				// eslint-disable-next-line no-console
			})
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	async getUserList(courseId) {
		try {
			const { data } = await axios.get(`/courses/users/${courseId}`)
			const userList = data.data
			runInAction(() => {
				if (data.data.length) {
					this.userList = userList.map((user) => {
						return {
							key: user.id,
							...user,
							date_create: user?.date_create ? dateFormat(user?.date_create) : null,
							date_payed: user?.date_payed ? dateFormat(user?.date_payed) : null,
							date_update: user?.date_update ? dateFormat(user?.date_update) : null,
						}
					})
				} else {
					this.userList = []
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	accessModalOpen = (event, userId, courseId) => {
		event.preventDefault()
		this.accessModal.isOpen = true
		this.accessModal.user_id = userId
		this.accessModal.course_id = courseId
	}

	accessModalOk = (event) => {
		event.preventDefault()
		this.accessCoursesUser(this.accessModal.user_id, this.accessModal.course_id, this.accessModal.select)

		this.accessModal.isOpen = false
		this.accessModal.user_id = null
		this.accessModal.course_id = null
	}

	accessModalClose = (event) => {
		event.preventDefault()
		this.accessModal.isOpen = false
		this.accessModal.user_id = null
		this.accessModal.course_id = null
	}

	accessModalChange = (event) => {
		this.accessModal.select = event
	}

	accessCoursesUser = (user_id, course_id, action) => {
		let data = new FormData()
		data.append("user_id", user_id)
		data.append("course_id", course_id)
		data.append("action", action)
		axios
			.post("/courses/black-list", data)
			.then((success) => {
				if (success.data === 1) {
					this.getUserList(course_id)
				}
			})
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}
}

export const coursesStore = new CoursesStore()
