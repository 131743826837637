import { Button, Col, Input, InputNumber, Popconfirm, Row, Space, message } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"

import { coursesStore } from "store/Courses/CoursesStore"

import "./CoursesNumbers.scss"

const CoursesNumbers = (props) => {
	const in_numbers = props.in_numbers
	if (!in_numbers) return <></>
	if (in_numbers && in_numbers.length > 0) {
		const cancel = (e) => {}
		const confirm = (item) => {
			if (in_numbers.find((number) => number.id !== item)) {
				in_numbers.splice(item, 1)
				message.success("Удалено")
			} else {
				message.error("Не удалено!")
			}
		}

		return (
			<Row gutter={[24, 24]}>
				{in_numbers.map((number, index) => {
					return (
						<Col
							key={index}
							xs={24}
							md={6}
							id={index}
							data-position={index}
							draggable
							onDragStart={(e) => coursesStore.onDragStart(e, "in_numbers")}
							onDragOver={(e) => coursesStore.onDragOver(e, "in_numbers")}
							onDrop={() => coursesStore.onDrop("in_numbers")}
							onDragLeave={(e) => coursesStore.onDragLeave(e, "in_numbers")}
							className={
								coursesStore.initStates.in_numbers &&
								coursesStore.initStates.in_numbers.draggedTo === Number(index)
									? "dropArea dropItem"
									: "dropItem"
							}
						>
							<Space className="drag" direction="vertical">
								<strong>Блок №{index + 1}</strong>

								<InputNumber
									placeholder="Цифра"
									min={0}
									controls={false}
									value={coursesStore.course.in_numbers[index].num}
									onChange={(e) => coursesStore.onChangeInNumbers("num", index, e)}
								/>

								<Input
									placeholder="Текст"
									value={coursesStore.course.in_numbers[index].text}
									onChange={(e) => coursesStore.onChangeInNumbers("text", index, e.target.value)}
								/>

								<Popconfirm
									title="Точно удалить цену?"
									onConfirm={() => confirm(index)}
									onCancel={cancel}
									okText="Удалить"
									cancelText="Отменить"
								>
									<Button type="danger">Удалить</Button>
								</Popconfirm>
							</Space>
						</Col>
					)
				})}
			</Row>
		)
	}
}

export default observer(CoursesNumbers)
