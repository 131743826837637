import SEO from "@americanexpress/react-seo"
import { Col, Row } from "antd"
import React, { useEffect } from "react"

import Header from "components/Header/Header"
import PersonalUser from "components/PersonalUser/PersonalUser"
import TitlePage from "components/TitlePage/TitlePage"

import { usersStore } from "store/Users/UsersStore"
import locals from "store/locals"

import "./PersonalPage.scss"

const PersonalPage = () => {
	useEffect(() => {
		usersStore.getUser(locals.get("user").id)
	})
	return (
		<>
			<SEO title="Личные данные" />
			<Header />
			<div className="container">
				<TitlePage title="Личные данные" />
				<Row gutter={[24, 24]} className="PersonalUser">
					<Col xs={24} sm={18} md={12} lg={9}>
						<PersonalUser />
					</Col>
				</Row>
			</div>
		</>
	)
}

export default PersonalPage
