const GetPaymentType = (systemID, amount) => {
	if (amount === 0) return "Бесплатно"
	let systemName = "Сбер"
	if (systemID === "2") systemName = "Рассрочка Сбер"
	else if (systemID === "3") systemName = "Рассрочка MK"
	else if (systemID === "4") systemName = "Карта"
	else if (systemID === "5") systemName = "Рассрочка Тинькофф"
	return systemName
}

export default GetPaymentType
