// Import Store
import { action, makeAutoObservable } from "mobx"

import dateFormat from "hooks/dateFormat"

// API
import axios from "../../axios"

class CoursesRegistration {
	// Begin - State
	statusCSV = false
	registration = {
		list: [],
		count: null,
		filter: {
			sortId: 0,
			email: null,
			phone: null,
		},
		listFull: [],
	}
	// End - State

	constructor() {
		makeAutoObservable(this)
	}

	// Begin - Actions
	onChangeFilter = (name, value) => {
		this.registration.filter[name] = value
	}

	// End - Actions

	// Begin - API
	filterPaymentCourse = (id) => {
		const params = {
			sort_id: this.registration.filter.sortId,
			filter: [],
		}

		if (this.registration.filter.email) {
			params.filter.push({ name: "email", value: this.registration.filter.email.trim() })
		}

		if (this.registration.filter.phone) {
			params.filter.push({ name: "phone", value: this.registration.filter.phone.trim() })
		}

		params.filter = JSON.stringify(params.filter)

		axios.get(`/courses/users/${id}`, { params }).then(
			action((success) => {
				const { count, data } = success.data
				this.registration.list = data
				this.registration.count = count
			}),
		)
	}

	getPaymentsCourse = (id) => {
		axios.get(`/courses/users/${id}`).then(
			action((success) => {
				const { count, data } = success.data
				this.registration.list = data
				this.registration.count = count
			}),
		)
	}

	getList = (id) => {
		const params = {
			sort_id: this.registration.filter.sortId,
			filter: [],
		}

		if (this.registration.filter.email) {
			params.filter.push({ name: "email", value: this.registration.filter.email.trim() })
		}

		if (this.registration.filter.phone) {
			params.filter.push({ name: "phone", value: this.registration.filter.phone.trim() })
		}

		params.filter = JSON.stringify(params.filter)

		axios.get(`/courses/users/${id}`, { params }).then(
			action((success) => {
				const { count, data } = success.data
				this.registration.list = data
				this.registration.count = count
				this.registration.listFull = success.data.data.map((item) => {
					return {
						...item,
						system_pay: item.system_pay.name,
						payment_date_create: dateFormat(item.payment_date_create),
						payment_date_payed: dateFormat(item.payment_date_payed),
					}
				})
				this.statusCSV = true
				setTimeout(
					action(() => {
						this.statusCSV = false
					}),
					1000,
				)
			}),
		)
	}

	// getList = (id) => {
	// 	console.debug(id, "qwerty")

	// 	const params = {
	// 		sort_id: this.registration.filter.sortId,
	// 		filter: [],
	// 	}

	// 	if (this.registration.filter.email) {
	// 		params.filter.push({ name: "email", value: this.registration.filter.email.trim() })
	// 	}

	// 	if (this.registration.filter.phone) {
	// 		params.filter.push({ name: "phone", value: this.registration.filter.phone.trim() })
	// 	}

	// 	params.filter = JSON.stringify(params.filter)

	// 	axios
	// 		.get(`/courses/users/${id}`, {
	// 			params,
	// 		})
	// 		.then(
	// 			action((success) => {
	// 				const [payments] = success.data

	// 				this.listFull = payments.map((payment) => {
	// 					return {
	// 						key: payment.id,
	// 						...payment,
	// 						// date_create: payment?.date_create ? dateFormat(payment?.date_create) : null,
	// 						// date_payed: payment?.date_payed ? dateFormat(payment?.date_payed) : null,
	// 						// date_update: payment?.date_update ? dateFormat(payment?.date_update) : null,
	// 					}
	// 				})
	// 				this.statusCSV = true
	// 				setTimeout(
	// 					action(() => {
	// 						this.statusCSV = false
	// 					}),
	// 					1000,
	// 				)
	// 			}),
	// 		)
	// }
	// End - API
}

export default new CoursesRegistration()
