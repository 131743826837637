import { message } from "antd"
import { action, makeAutoObservable } from "mobx"

import axios from "../../axios"

class CategoriesStore {
	constructor() {
		makeAutoObservable(this)
	}

	listCategories = []
	newCategories = {}
	total = ""
	limit = 15
	offset = 0
	pageSize = 15
	listFull = []

	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize
			this.offset = this.getAllCategories(this.newItems, offset)
		},
	}

	onChange(name, value) {
		this.newCategories[name] = value
	}

	getAllCategories(limit = this.limit, offset = this.offset) {
		axios
			.get("/categories/categories/", {
				params: {
					limit: limit,
					offset: offset,
				},
			})
			.then(
				action((success) => {
					const [categories, count] = success.data
					this.total = count[0].count
					this.pagination.total = this.total
					this.listCategories = categories.map((category) => {
						return { key: category.id, ...category }
					})
				}),
			)
	}

	getFullCategories() {
		axios
			.get("/categories/categories/", {
				params: {
					limit: this.total,
				},
			})
			.then(
				action((success) => {
					const [categories] = success.data
					this.listFull = categories.map((category) => {
						return { key: category.id, ...category }
					})
				}),
			)
	}

	getCategories(id) {
		axios
			.get(`/categories/category/${id}`)
			.then(action((success) => (this.newCategories = success.data)))
			.catch((error) => {
				if (error.request.statue === 400) {
					window.location.href = "/"
				}
			})
	}

	setCategories() {
		this.newCategories = {
			id: 0,
			name: "",
		}
	}

	editCategories(id) {
		let category = new FormData()
		let url

		category.append("name", this.newCategories.name)
		if (id > 0) {
			url = `edit/${id}`
		} else {
			url = "new"
		}

		axios
			.post(`/categories/${url}`, category)
			.then(
				action((success) => {
					message.success("Успешно")
					document.location.href = "/categories"
				}),
			)
			.catch((error) => {
				message.error("Ошибка")
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	deleteCategories(key) {
		axios
			.delete(`/categories/category/${key}`)
			.then(
				action((success) => {
					message.success("Успешно")
					this.getAllCategories(this.limit, this.offset)
				}),
			)
			.catch((error) => {
				if (error.request.status === 403) {
					message.error("Категория имеет привязанные курсы!")
				}
			})
	}
}

export const categoriesStore = new CategoriesStore()
