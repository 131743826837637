import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { observer } from "mobx-react-lite"
import { NavLink } from "react-router-dom"

import { coursesStore } from "store/Courses/CoursesStore"
import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

const WebinarsList = (props) => {
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Имя",
			dataIndex: "name",
			render: (text, record) => {
				return <NavLink to={`/webinars/${record.id}`}>{text}</NavLink>
			},
		},
		{
			title: "Связанный курс",
			dataIndex: "courseLink",
			render: (_, webinar) => {
				const item = coursesStore.listCourses.find((course) => {
					return Number(course.id) === Number(webinar.course)
				})
				return (
					<>
						{item?.title} {item?.city && item?.city !== "null" ? <>({item?.city})</> : null}
						<br />
						старт {item?.date_start.split(" ")[0]}
					</>
				)
			},
		},
		{
			title: "Статус вебинара",
			dataIndex: "status",
			width: "100px",
			render(item) {
				let status
				if (Number(item) === 1) {
					status = "Активен"
				} else {
					status = "Не активен"
				}
				return <div>{status}</div>
			},
		},
		{
			title: "Дата создания",
			dataIndex: "date_create",
			width: "136px",
		},
		{
			title: "Дата изменения",
			dataIndex: "date_update",
			width: "140px",
		},
		{
			title: "Дата старта",
			dataIndex: "date_start",
			width: "136px",
		},
		{
			title: "Регистрации",
			dataIndex: "reg",
			width: "125px",
			render: (_, record) => {
				return (
					<NavLink
						to={`/webinars/registration/${record.id}`}
						style={{
							marginRight: 8,
						}}
					>
						Регистрации
					</NavLink>
				)
			},
		},
		{
			title: "Действия",
			dataIndex: "operation",
			width: "100px",
			render: (_, record) => {
				return (
					<Space>
						<span>
							<NavLink
								to={`/webinars/${record.id}`}
								style={{
									marginRight: 8,
								}}
							>
								<EditOutlined />
							</NavLink>

							<Popconfirm
								title="Удалить запись?"
								onConfirm={() => webinarsStore.deleteWebinar(record.key)}
								okText="Да"
								cancelText="Нет"
							>
								<Button type="link">
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</span>
					</Space>
				)
			},
		},
	]
	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={webinarsStore.pagination}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
}

export default observer(WebinarsList)
