// Import Framework
import SEO from "@americanexpress/react-seo"
import { Button, Input, Select, Space } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"
import TitlePage from "components/TitlePage/TitlePage"
import WebinarsRegistrationList from "components/WebinarsRegistrationList/WebinarsRegistrationList"

import { coursesStore } from "store/Courses/CoursesStore"
import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

// Import Style
import "./WebinarsRegistrationPage.scss"

const { Option } = Select
const WebinarsRegistrationPage = () => {
	const params = useParams()
	webinarsStore.idWebinarsRegistration = params.id
	useEffect(() => {
		coursesStore.getAllCourses()
		webinarsStore.getWebinarsRegistration(webinarsStore.limit, webinarsStore.offset, params.id)
	}, [params.id])
	return (
		<>
			<SEO title="Список зарегистрированных" />
			<HeaderComponent />
			<div className="container">
				<div className="WebinarsRegistrationPage">
					<TitlePage
						title="Список зарегистрированных"
						fileName="WebinarsReg"
						listFull={webinarsStore.listFullwebinarReg}
						getList={webinarsStore.getListRegWebinars}
						store={webinarsStore}
						paramsId={params.id}
					/>

					<form
						className="UsersPageForm"
						onSubmit={(e) => {
							e.preventDefault()
							webinarsStore.getFiltersWebinarsRegistration(params.id)
						}}
					>
						<Space align="end" size={24}>
							<Space direction="vertical">
								<span>Сортировка</span>
								<Select
									style={{
										width: 160,
									}}
									defaultValue={0}
									value={webinarsStore.filter.sortId}
									onChange={(e) => webinarsStore.onChangeFilter("sortId", e)}
								>
									<Option value={0}>По убыванию</Option>
									<Option value={1}>По возрастанию</Option>
								</Select>
							</Space>

							<Space direction="vertical">
								<span>Поиск по email</span>
								<Input
									placeholder="Email"
									value={webinarsStore.filter.filter.email}
									allowClear
									onChange={(e) => {
										webinarsStore.onChangeFilterInput("email", e.target.value)
									}}
								/>
							</Space>

							<Space direction="vertical">
								<span>Поиск по телефону</span>
								<Input
									placeholder="Телефон"
									value={webinarsStore.filter.filter.phone}
									allowClear
									onChange={(e) => {
										webinarsStore.onChangeFilterInput("phone", e.target.value)
									}}
								/>
							</Space>

							<Space direction="vertical">
								<span>Сортировка по курсам</span>
								<Select
									placeholder="Выберите курсы"
									mode="multiple"
									allowClear
									style={{
										width: 360,
									}}
									defaultValue={webinarsStore.filter.purchased_courses}
									value={webinarsStore.filter.purchased_courses}
									onChange={(e) => webinarsStore.onChangeFilter("purchased_courses", e)}
								>
									{coursesStore.listCourses.map((course) => {
										return (
											<Option key={course.id} value={course.id}>
												{course.title}{" "}
												{course?.city && course?.city !== "null" ? <>({course?.city})</> : null}
											</Option>
										)
									})}
								</Select>
							</Space>

							<Button type="primary" htmlType="submit">
								Применить
							</Button>
						</Space>
					</form>

					<WebinarsRegistrationList dataSource={webinarsStore.webinarReg} items={webinarsStore.totalReg} />
				</div>
			</div>
		</>
	)
}

export default observer(WebinarsRegistrationPage)
