// Import Components
import { Button, Col, Input, Row, Select, Space } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import TitlePage from "components/TitlePage/TitlePage"
import UsersForm from "components/UsersList"

import { coursesStore } from "store/Courses/CoursesStore"
import { usersStore } from "store/Users/UsersStore"

const { Option } = Select
export const Users = observer(() => {
	useEffect(() => {
		usersStore.getAllUsers(usersStore.limit, usersStore.offset, "users")
		coursesStore.getAllCourses(9999)
	}, [])

	if (!usersStore.UsersList) return <></>

	return (
		<>
			<TitlePage
				title="Cписок пользователей"
				buttonText="Добавить пользователя"
				buttonLink="/users/0"
				fileName="users"
				listFull={usersStore.UsersFullList}
				getList={(props = { limit: 0 }) => usersStore.getListUsers(props)}
				store={usersStore}
			/>

			<form
				className="UsersPageForm"
				onSubmit={(e) => {
					e.preventDefault()
					usersStore.getFiltersUsers()
				}}
			>
				<Row gutter={[15, 15]}>
					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Сортировка</span>
							<Select
								style={{
									width: "100%",
								}}
								defaultValue={0}
								value={usersStore.filter.sortId}
								onChange={(e) => usersStore.onChangeFilter("sortId", e)}
							>
								<Option value={0}>По убыванию</Option>
								<Option value={1}>По возрастанию</Option>
							</Select>
						</Space>
					</Col>

					<Col span={8}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Сортировка по курсам</span>
							<Select
								placeholder="Выберите курсы"
								mode="multiple"
								allowClear
								style={{
									width: "100%",
								}}
								defaultValue={usersStore.filter.purchased_courses}
								value={usersStore.filter.purchased_courses}
								onChange={(e) => usersStore.onChangeFilter("purchased_courses", e)}
							>
								{coursesStore.listCourses.map((course) => {
									return (
										<Option key={course.id} value={course.id}>
											{course.title}{" "}
											{course?.city && course?.city !== "null" ? <>({course?.city})</> : null}
										</Option>
									)
								})}
							</Select>
						</Space>
					</Col>

					<Col span={8}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Исключение курсов</span>
							<Select
								placeholder="Выберите курсы"
								mode="multiple"
								allowClear
								style={{
									width: "100%",
								}}
								defaultValue={usersStore.filter.notBuyCourses}
								value={usersStore.filter.notBuyCourses}
								onChange={(e) => usersStore.onChangeFilter("notBuyCourses", e)}
							>
								{coursesStore.listCourses.map((course) => {
									return (
										<Option key={course.id} value={course.id}>
											{course.title}{" "}
											{course?.city && course?.city !== "null" ? <>({course?.city})</> : null}
										</Option>
									)
								})}
							</Select>
						</Space>
					</Col>

					<Col span={4}></Col>

					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Поиск по имени</span>
							<Input
								placeholder="Имя"
								value={usersStore.filter.filter.name}
								allowClear
								onChange={(e) => {
									usersStore.onChangeFilterInput("name", e.target.value)
								}}
							/>
						</Space>
					</Col>

					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Поиск по фамилии</span>
							<Input
								placeholder="Фамилия"
								value={usersStore.filter.filter.surname}
								allowClear
								onChange={(e) => {
									usersStore.onChangeFilterInput("surname", e.target.value)
								}}
							/>
						</Space>
					</Col>

					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Поиск по телефону</span>
							<Input
								placeholder="Телефон"
								value={usersStore.filter.filter.phone}
								allowClear
								onChange={(e) => {
									usersStore.onChangeFilterInput("phone", e.target.value)
								}}
							/>
						</Space>
					</Col>

					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Поиск по email</span>
							<Input
								placeholder="Email"
								value={usersStore.filter.filter.email}
								allowClear
								onChange={(e) => {
									usersStore.onChangeFilterInput("email", e.target.value)
								}}
							/>
						</Space>
					</Col>

					<Col span={4}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span>Поиск по городу</span>
							<Input
								placeholder="Город"
								value={usersStore.filter.filter.city}
								allowClear
								onChange={(e) => {
									usersStore.onChangeFilterInput("city", e.target.value)
								}}
							/>
						</Space>
					</Col>

					<Col span={3}>
						<Space
							direction="vertical"
							style={{
								width: "100%",
							}}
						>
							<span style={{ visibility: "hidden" }}>Применить</span>
							<Button type="primary" htmlType="submit" block>
								Применить
							</Button>
						</Space>
					</Col>
				</Row>
			</form>

			<UsersForm type="users" dataSource={usersStore.UsersList} items={usersStore.totalUsers} />
		</>
	)
})
