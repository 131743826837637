// Import Framework
// import { Space, Select, Button, Input } from "antd"
import SEO from "@americanexpress/react-seo"
import { Button, Col, Input, Row, Select, Space } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { CoursesRegistrationTable } from "components/Courses/CoursesRegistrationTable/CoursesRegistrationTable"
import HeaderComponent from "components/Header/Header"
import TitlePage from "components/TitlePage/TitlePage"

// import { webinarsStore } from "store/WebinarsStore/WebinarsStore"
// import { coursesStore } from "store/Courses/CoursesStore"
import store from "./CoursesRegistration.store"
// Import Style
import "./CoursesRegistrationPage.scss"

const { Option } = Select

const CoursesRegistrationPage = () => {
	const params = useParams()

	useEffect(() => {
		store.getPaymentsCourse(params.id)
	}, [params.id])

	return (
		<>
			<SEO title="Список оплативших курс" />
			<HeaderComponent />
			<div className="container">
				<div className="WebinarsRegistrationPage">
					<TitlePage
						title="Список оплативших курс"
						fileName="CoursesReg"
						listFull={store.registration.listFull}
						getList={store.getList}
						store={store}
						paramsId={params.id}
					/>
					<form
						style={{ marginBottom: "30px" }}
						onSubmit={(event) => {
							event.preventDefault()
							store.filterPaymentCourse(params.id)
						}}
					>
						<Row gutter={[15, 15]}>
							<Col span={5}>
								<Space direction="vertical" style={{ width: "100%" }}>
									<span>Сортировка</span>
									<Select
										style={{
											width: "100%",
										}}
										defaultValue={0}
										value={store.registration.filter.sortId}
										onChange={(e) => store.onChangeFilter("sortId", e)}
									>
										<Option key={0} value={0}>
											По убыванию
										</Option>
										<Option key={1} value={1}>
											По возрастанию
										</Option>
									</Select>
								</Space>
							</Col>

							<Col span={7}>
								<Space
									direction="vertical"
									style={{
										width: "100%",
									}}
								>
									<span>Поиск по email</span>
									<Input
										placeholder="Введите Email"
										allowClear
										onChange={(event) => store.onChangeFilter("email", event.target.value)}
									/>
								</Space>
							</Col>

							<Col span={6}>
								<Space
									direction="vertical"
									style={{
										width: "100%",
									}}
								>
									<span>Поиск по номеру телефона</span>
									<Input
										placeholder="Введите номер"
										allowClear
										onChange={(event) => store.onChangeFilter("phone", event.target.value)}
									/>
								</Space>
							</Col>

							<Col span={4}>
								<Space
									direction="vertical"
									style={{
										width: "100%",
									}}
								>
									<span style={{ visibility: "hidden" }}>Применить</span>
									<Button type="primary" htmlType="submit" block>
										Применить
									</Button>
								</Space>
							</Col>
						</Row>
					</form>

					<CoursesRegistrationTable list={store.registration.list} count={store.registration.count} />
				</div>
			</div>
		</>
	)
}

export default observer(CoursesRegistrationPage)
