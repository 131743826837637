import { message } from "antd"
import { action, makeAutoObservable } from "mobx"

import axios from "../../axios"
import locals from "../locals"

class AuthStore {
	constructor() {
		makeAutoObservable(this)
	}

	user = {
		login: "",
		password: "",
	}

	onChange(name, value) {
		this.user[name] = value
	}

	LogIn(e) {
		e.preventDefault()
		let facts = new FormData()
		facts.append("login", this.user.login)
		facts.append("password", this.user.password)
		axios
			.post("user/login", facts)
			.then(
				action((success) => {
					if (success.data !== 0) {
						locals.set("user", success.data)
						setTimeout(() => {
							document.location.href = "/"
						}, 1000)
					} else {
						message.error("Неправильный логин или пароль.")
					}
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	LogOut(e) {
		e.preventDefault()
		locals.remove("user")
		document.location.href = "/"
	}
}

export const authStore = new AuthStore()
