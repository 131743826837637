import { ExportOutlined, UserOutlined } from "@ant-design/icons"
import { Layout, Menu } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

import logo from "assets/images/logo.png"

import { authStore } from "store/Auth/AuthStore"
import { categoriesStore } from "store/Categories/CategoriesStore"
import locals from "store/locals"

import "./Header.scss"

const Header = Layout
const HeaderComponent = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const user = locals.get("user")
	useEffect(() => {
		categoriesStore.getAllCategories()
	})

	if (!user) return <></>

	const items = [
		{
			label: (
				<NavLink to="/courses">
					<span>Курсы</span>
				</NavLink>
			),
			key: "courses",
		},
		{
			label: (
				<NavLink to="/categories">
					<span>Категории</span>
				</NavLink>
			),
			key: "categories",
		},
		{
			label: (
				<NavLink to="/payment">
					<span>Оплаты</span>
				</NavLink>
			),
			key: "payment",
		},
		{
			label: (
				<NavLink to="/team">
					<span>Команда</span>
				</NavLink>
			),
			key: "team",
		},
		{
			label: (
				<NavLink to="/webinars">
					<span>Вебинары</span>
				</NavLink>
			),
			key: "webinars",
		},
		{
			label: (
				<NavLink to="/promocode">
					<span>Промокоды</span>
				</NavLink>
			),
			key: "promocode",
		},
		{
			label: (
				<NavLink to="/users">
					<span>Пользователи</span>
				</NavLink>
			),
			key: "users",
		},
		{
			label: (
				<NavLink to="/admins">
					<span>Админы</span>
				</NavLink>
			),
			key: "admins",
		},
		{
			label: (
				<NavLink to="/personal">
					<UserOutlined />
				</NavLink>
			),
			key: "personal",
		},
		{
			label: (
				<NavLink to="/" onClick={(e) => authStore.LogOut(e)}>
					<ExportOutlined />
				</NavLink>
			),
			key: "logout",
		},
	]

	return (
		<Layout className="layout header-layout">
			<Header className="container-large">
				<button className="logo" onClick={() => navigate("/courses")}>
					<img src={logo} alt="Modnaya Kontora" />
				</button>
				<Menu selectedKeys={[location.pathname]} theme="light" mode="horizontal" items={items} />
			</Header>
		</Layout>
	)
}

export default observer(HeaderComponent)
