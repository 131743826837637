// Import Framework
import { Button, Col, Input, Row, Select, Space, Typography } from "antd"
// Import Store
import { observer } from "mobx-react-lite"
// Import Components
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { teamStore } from "store/Team/TeamStore"
import { usersStore } from "store/Users/UsersStore"

// Import Hooks

const { Title } = Typography
const { Option } = Select
export const UserForm = observer(() => {
	const params = useParams()
	useEffect(() => {
		if (Number(params.id) > 0) {
			usersStore.getUser(Number(params.id), "all")
		} else {
			usersStore.setUser()
		}
		teamStore.getAllTeam(teamStore.limit, teamStore.offset)
	}, [params.id])

	if (!usersStore.newUser) return <></>

	const itemsRole = [
		{ value: 0, name: "Пользователь" },
		// { value: 1, name: "Только просмотр" },
		// { value: 2, name: "Продажник" },
		{ value: 3, name: "Менеджер" },
		{ value: 4, name: "Администратор" },
		{ value: 5, name: "Разработчик" },
	]

	return (
		<form onSubmit={(e) => usersStore.UserSignUp(e)} className="CreateUsers">
			<Row className="CreateUsers__row" gutter={[15, 24]}>
				<Col xs={24} lg={18} className="CreateUsers__title">
					<Row gutter={[15, 24]} justify="space-between">
						<Col xs={24} md={20}>
							<Title>{params.id > 0 ? "Редактирование пользователя" : "Новый пользователь"}</Title>
						</Col>

						<Col xs={24} md={4} className="CreateUsers__title-block">
							<Button size="large" type="primary" htmlType="submit" className="CreateUsers__title-button">
								{params.id > 0 ? "Сохранить" : "Создать"}
							</Button>
						</Col>
					</Row>
				</Col>

				<Col xs={24} lg={18} className="CreateUsers__content">
					<Row gutter={[15, 24]}>
						<Col xs={24} lg={8}>
							<Space direction="vertical">
								<Title level={4}>Логин (Для администраторов)</Title>
								<Input
									size="large"
									placeholder="Введите логин"
									value={usersStore.newUser.login}
									onChange={(e) => usersStore.onChange("login", e.target.value)}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={8}>
							<Space direction="vertical">
								<Title level={4}>Имя</Title>
								<Input
									size="large"
									placeholder="Введите имя"
									required
									value={usersStore.newUser.name}
									onChange={(e) => usersStore.onChange("name", e.target.value)}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={8}>
							<Space direction="vertical">
								<Title level={4}>Фамилия</Title>
								<Input
									size="large"
									placeholder="Введите фамилию"
									required
									value={usersStore.newUser.surname}
									onChange={(e) => usersStore.onChange("surname", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>

					<Row gutter={[15, 24]} className="CreateUsers__city">
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Страна</Title>
								<Input
									size="large"
									placeholder="Введите страну"
									value={usersStore.newUser.country}
									onChange={(e) => usersStore.onChange("country", e.target.value)}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Город</Title>
								<Input
									size="large"
									placeholder="Введите город"
									value={usersStore.newUser.city}
									onChange={(e) => usersStore.onChange("city", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>

					<Row gutter={[15, 24]}>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Права доступа</Title>
								<Select
									size="large"
									value={usersStore.newUser.permissions}
									onChange={(e) => {
										usersStore.onChange("permissions", e)
									}}
								>
									{itemsRole.map((item, index) => {
										return (
											<Option key={index} value={item.value}>
												{item.name}
											</Option>
										)
									})}
								</Select>
							</Space>
						</Col>

						{/*<Col xs={24} lg={12}>*/}
						{/*	<Space direction="vertical">*/}
						{/*		<Title level={4}>Куратор</Title>*/}
						{/*		<Select*/}
						{/*			size="large"*/}
						{/*			value={*/}
						{/*				usersStore.newUser.curator === null ? "Выберите куратора" : usersStore.newUser.curator*/}
						{/*			}*/}
						{/*			placeholder="Выберите куратора"*/}
						{/*			onChange={(e) => {*/}
						{/*				usersStore.onChange("curator", e)*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<Option key={-1} value="0">*/}
						{/*				Нет куратора*/}
						{/*			</Option>*/}
						{/*			{teamStore.listTeam.map((team, index) => {*/}
						{/*				return (*/}
						{/*					<Option key={index} value={team.id}>*/}
						{/*						{team.name}*/}
						{/*					</Option>*/}
						{/*				)*/}
						{/*			})}*/}
						{/*		</Select>*/}
						{/*	</Space>*/}
						{/*</Col>*/}
					</Row>

					<Row gutter={[15, 24]}>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Номер телефона</Title>
								<Input
									size="large"
									placeholder="Введите номер"
									required
									value={usersStore.newUser.phone}
									onChange={(e) => {
										let value = e.target.value.replace(/[^0-9.]/, "")
										usersStore.onChange("phone", value)
									}}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>E-mail</Title>
								<Input
									size="large"
									placeholder="Введите E-mail"
									required
									value={usersStore.newUser.email}
									onChange={(e) => usersStore.onChange("email", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>

					<Row gutter={[15, 24]}>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>VK</Title>
								<Input
									size="large"
									placeholder="Введите vk"
									required
									value={usersStore.newUser.vk}
									onChange={(e) => usersStore.onChange("vk", e.target.value)}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Instagram</Title>
								<Input
									size="large"
									placeholder="Введите Instagram"
									required
									value={usersStore.newUser.instagram}
									onChange={(e) => usersStore.onChange("instagram", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>

					<Row gutter={[15, 24]}>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Пароль</Title>
								<Input.Password
									size="large"
									placeholder="Введите пароль"
									value={usersStore.newUser.password}
									onChange={(e) => usersStore.onChange("password", e.target.value)}
								/>
							</Space>
						</Col>
						<Col xs={24} lg={12}>
							<Space direction="vertical">
								<Title level={4}>Подтверждение пароля</Title>
								<Input.Password
									size="large"
									placeholder="Введите ещё раз пароль"
									value={usersStore.newUser.passwordConfirmation}
									onChange={(e) => usersStore.onChange("passwordConfirmation", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>
		</form>
	)
})
