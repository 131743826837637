import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"
import { Payment } from "components/Promocode/payment/payment"
import TitlePage from "components/TitlePage/TitlePage"

import { store } from "store/Promocode/Promocode.store"

import styles from "./PaymentPromoCodePage.module.scss"

const PaymentPromoCodePage = () => {
	const { id } = useParams()
	useEffect(() => {
		store.getPaymentsPromocode(id)
	}, [])

	if (!store.paymentPromocode.success) {
		return null
	}

	return (
		<>
			<SEO title="Cписок применивших промокод" />
			<HeaderComponent />
			<div className="container">
				<TitlePage title="Cписок применивших промокод" />

				<Payment data={store.paymentPromocode.data} />
			</div>
		</>
	)
}

export default observer(PaymentPromoCodePage)
