// Import Store
import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"

import HeaderComponent from "components/Header/Header"
// Import Components
import { Admins, Users } from "components/Users"

// Import Style
import "./UsersPage.scss"

const UsersPage = (props) => {
	const { user } = props
	const location = useLocation()

	return (
		<>
			<SEO
				title={location.pathname === "/admins" && user === "admin" ? "Cписок админов" : "Список пользователей"}
			/>
			<HeaderComponent />

			<div className="container">
				{location.pathname === "/users" && user === "user" && <Users />}
				{location.pathname === "/admins" && user === "admin" && <Admins />}
			</div>
		</>
	)
}

export default observer(UsersPage)
