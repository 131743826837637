import SEO from "@americanexpress/react-seo"
import { Button, Col, Input, Row, Space, Typography } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"

import { categoriesStore } from "store/Categories/CategoriesStore"

import "./CreateCategoriesPage.scss"

const { Title } = Typography
const CreateCategoriesPage = () => {
	const params = useParams()
	const handleSubmit = (event) => {
		event.preventDefault()
		categoriesStore.editCategories(params.id)
	}

	useEffect(() => {
		if (params.id > 0) {
			categoriesStore.getCategories(Number(params.id))
		} else {
			categoriesStore.setCategories()
		}
	}, [params.id])
	return (
		<>
			<SEO title={params.id > 0 ? "Редактирование категории" : "Новая категория"} />
			<HeaderComponent />
			<div className="container">
				<form onSubmit={handleSubmit} className="CreateCategories">
					<Row className="CreateCategories__row" gutter={[15, 24]}>
						<Col xs={24} lg={18} className="CreateCategories__title">
							<Row gutter={[15, 24]} justify="space-between">
								<Col xs={24} md={20}>
									<Title>{params.id > 0 ? "Редактирование категории" : "Новая категория"}</Title>
								</Col>

								<Col xs={24} md={4} className="CreateCategories__title-block">
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										className="CreateCategories__title-button"
									>
										{params.id > 0 ? "Сохранить" : "Создать"}
									</Button>
								</Col>
							</Row>
						</Col>

						<Col xs={24} lg={18}>
							<Space className="CreateCategories" direction="vertical" size={18}>
								<Title level={4}>Название</Title>
								<Input
									size="large"
									placeholder="Введите название категории"
									required
									value={categoriesStore.newCategories.name}
									onChange={(e) => categoriesStore.onChange("name", e.target.value)}
								/>
							</Space>
						</Col>
					</Row>
				</form>
			</div>
		</>
	)
}

export default observer(CreateCategoriesPage)
