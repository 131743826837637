import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { observer } from "mobx-react-lite"
import { NavLink } from "react-router-dom"

import { categoriesStore } from "store/Categories/CategoriesStore"

const CategoriesList = observer((props) => {
	const columns = [
		{
			title: "id",
			dataIndex: "id",
		},
		{
			title: "Название категории",
			dataIndex: "name",
			render: (text, record) => <NavLink to={`/categories/${record.id}`}>{text}</NavLink>,
		},
		{
			title: "Редактирование",
			dataIndex: "operation",
			width: "25%",
			render: (_, record) => {
				return (
					<Space>
						<span>
							<NavLink
								style={{
									marginRight: 8,
								}}
								to={`/categories/${record.id}`}
							>
								<EditOutlined />
							</NavLink>

							<Popconfirm
								title="Удалить запись?"
								onConfirm={() => categoriesStore.deleteCategories(record.key)}
								okText="Да"
								cancelText="Нет"
							>
								<Button type="link">
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</span>
					</Space>
				)
			},
		},
	]

	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={categoriesStore.pagination}
				showSizeChanger={false}
			/>

			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
})

export default CategoriesList
