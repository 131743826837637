// Import Store
import { message } from "antd"
import { action, makeAutoObservable } from "mobx"
import moment from "moment"

import locals from "store/locals"

import dateFormat from "hooks/dateFormat"

// API
import axios from "../../axios"

class Promocode {
	// Begin - State
	list = {
		success: false,
		items: [],
		count: 0,
	}
	filter = {
		promocode: "",
		courses: [],
		date: {
			start: null,
			end: null,
		},
	}
	form = {
		name: "",
		description: "",
		promocode: {
			name: "",
			type: 0,
		},
		date: {
			start: "",
			end: "",
		},
		courses: [],
		checked: false,
		discount: {
			amount: {
				interest: 5,
				price: 1000,
			},
			type: 0,
		},
	}
	dataExport = []
	statusCSV = false
	limit = 15
	offset = 0
	total = ""
	pageSize = 15
	paymentPromocode = {
		data: [],
		success: false,
	}
	pagination = {
		pageSize: this.pageSize,
		total: this.total,
		onChange: (current) => {
			const offset = Number(current) * Number(this.pageSize) - Number(this.pageSize)
			this.newItems = this.pageSize

			this.offset = this.getListPromocodes(this.newItems, offset)
		},
	}
	// End - State

	constructor() {
		makeAutoObservable(this)
	}

	// Begin - Actions
	onChange = (name, value) => {
		this.form[name] = value
	}

	onChangeFilter = (name, value) => {
		this.filter[name] = value
	}

	onChangeFilterDate = (date) => {
		this.filter.date.start = date !== null ? Math.trunc(moment(date[0]) / 1000) : null
		this.filter.date.end = date !== null ? Math.trunc(moment(date[1]) / 1000) : null
	}

	onChangePromocode = (name, value) => {
		this.form.promocode[name] = value
	}

	onChangeDiscountInput = (event, name) => {
		console.log(event)
		this.form.discount.amount[name] = event
	}

	onChangeDiscountSelect = (event) => {
		this.form.discount.type = event
	}

	onChangeDate = (date) => {
		this.form.date.start = date !== null ? date[0].unix() : null
		this.form.date.end = date !== null ? date[1].unix() : null
	}

	onSwitch = () => {
		this.form.checked = !this.form.checked
	}

	exportPayments = (data) => {
		if (data.length > 0) {
			this.dataExport = data.map((item) => {
				return {
					Наименование: item.name,
					Промокод: `(ID: ${item.promocode.id}) ${item.promocode.title}`,
					"ID заказа": item.id,
					Курс: `(ID: ${item.course.id}) ${item.course.title} ${item.course.city} ${item.course.course_type}`,
					Стоимость: item.amount,
					Скидка: item.discount,
					ФИО: item.fio,
					"ID транзакции": item.extId,
					"Дата оплаты": dateFormat(item.datePayed, "DD.MM.YYYY"),
					"Способ оплаты": item.system.name,
					Тип: item.type,
				}
			})

			this.statusCSV = true
			setTimeout(
				action(() => {
					this.statusCSV = false
				}),
				1000,
			)
		} else {
			return message.error("Нет использований!")
		}
	}
	// End - Actions

	// Begin - API
	getListPromocodes = async (limit, offset) => {
		limit = limit || this.limit
		offset = offset || this.offset

		const params = {
			"User-Token": locals.get("user").token,
			limit: limit,
			offset: offset,
		}

		await axios
			.get("/promocodes/list", { params })
			.then(
				action((response) => {
					const { success, data } = response.data
					this.list = {
						success: success,
						items: data.items.map((item) => {
							return { key: item.id, ...item }
						}),
						count: data.count,
					}
					this.pagination.total = data.count
				}),
			)
			.catch((error) => {
				// https://doka.guide/js/errors/
				throw new Error(`Ошибка ${error}`)
			})
	}

	getFilterListPromocodes = async () => {
		const params = {
			"User-Token": locals.get("user").token,
			limit: this.limit,
			offset: this.offset,
			promocode: this.filter.promocode ? this.filter.promocode : null,
			courses: this.filter.courses ? JSON.stringify(this.filter.courses) : null,
			date_create: this.filter.date.start ? this.filter.date.start : null,
			date_end: this.filter.date.end ? this.filter.date.end : null,
		}

		await axios
			.get("/promocodes/list", { params })
			.then(
				action((response) => {
					const { success, data } = response.data
					this.list = {
						success: success,
						items: data.items.map((item) => {
							return { key: item.id, ...item }
						}),
						count: data.count,
					}
					this.pagination.total = data.count
				}),
			)
			.catch((error) => {
				// https://doka.guide/js/errors/
				throw new Error(`Ошибка ${error}`)
			})
	}

	getPromocodeId = (id) => {
		const regexp = /%/
		const params = { "User-Token": locals.get("user").token, promocodeId: id }
		axios.get(`/promocodes/detail/${id}`, { params }).then(
			action((response) => {
				const { data, success } = response.data

				this.form.name = data.name
				this.form.description = data.description
				this.form.promocode = {
					name: data.promocode,
				}
				this.form.date = {
					start: data.dateStart,
					end: data.dateEnd,
				}
				this.form.courses = data.courses
				this.form.checked = data.status

				this.form.promocode.type = data.type === "Многоразовый" ? 0 : 1
				// this.discount
				if (regexp.test(data.discount)) {
					// %
					this.form.discount = {
						amount: {
							interest: data.discount.replace(/[^0-9]/g, ""),
							price: 1000,
						},
						type: 1,
					}
				} else {
					// Рубли
					this.form.discount = {
						amount: {
							interest: 5,
							price: data.discount.replace(/[^0-9]/g, ""),
						},
						type: 0,
					}
				}
			}),
		)
	}

	patchCreatePromocode = (id) => {
		const data = {}
		const config = { params: { "User-Token": locals.get("user").token, promocodeId: id } }

		if (this.form.name !== "") {
			Object.assign(data, { name: this.form.name })
		}

		if (this.form.description !== "") {
			Object.assign(data, { description: this.form.description })
		}

		if (this.form.date.start !== null) {
			Object.assign(data, { date_start: this.form.date.start })
		}

		if (this.form.date.end !== null) {
			Object.assign(data, { date_end: this.form.date.end })
		}

		if (this.form.discount.type === 0) {
			Object.assign(data, { discount: this.form.discount.amount.price })
		}

		if (this.form.discount.type === 1) {
			Object.assign(data, { discount: `${this.form.discount.amount.interest}%` })
		}

		Object.assign(data, { status: this.form.checked })

		if (this.form.promocode.name !== "") {
			Object.assign(data, { promocode: this.form.promocode.name })
		}

		if (this.form.promocode.type === 0) {
			Object.assign(data, { type: "reusable" })
		}

		if (this.form.promocode.type === 1) {
			Object.assign(data, { type: "one-time" })
		}

		Object.assign(data, { courses: this.form.courses })

		axios
			.patch(`/promocodes/edit/${id}`, data, config)
			.then(
				action((response) => {
					const { success } = response.data
					if (success) {
						this.form = {
							name: "",
							description: "",
							promocode: {
								name: "",
								type: 0,
							},
							date: {
								start: null,
								end: null,
							},
							courses: [],
							checked: false,
							discount: {
								amount: {
									interest: 5,
									price: 1000,
								},
								type: 0,
							},
						}

						return (window.location.href = "/promocode")
					}
				}),
			)
			.catch((error) => {
				console.dir(error)
			})
	}

	postCreatePromocode = () => {
		const data = {}

		if (this.form.name !== "") {
			Object.assign(data, { name: this.form.name })
		}

		if (this.form.description !== "") {
			Object.assign(data, { description: this.form.description })
		}

		if (this.form.date.start !== null) {
			Object.assign(data, { date_start: this.form.date.start })
		}

		if (this.form.date.end !== null) {
			Object.assign(data, { date_end: this.form.date.end })
		}

		if (this.form.discount.type === 0) {
			Object.assign(data, { discount: this.form.discount.amount.price })
		}

		if (this.form.discount.type === 1) {
			Object.assign(data, { discount: `${this.form.discount.amount.interest}%` })
		}

		Object.assign(data, { status: this.form.checked })

		if (this.form.promocode.name !== "") {
			Object.assign(data, { promocode: this.form.promocode.name })
		}

		if (this.form.promocode.type === 0) {
			Object.assign(data, { type: "reusable" })
		}

		if (this.form.promocode.type === 1) {
			Object.assign(data, { type: "one-time" })
		}

		Object.assign(data, { courses: this.form.courses })

		const params = { "User-Token": locals.get("user").token }
		axios.post("/promocodes/new", data, { params }).then((response) => {
			const { success } = response.data
			if (success) {
				this.form = {
					name: "",
					description: "",
					promocode: {
						name: "",
						type: 0,
					},
					date: {
						start: null,
						end: null,
					},
					courses: [],
					checked: false,
					discount: {
						amount: {
							interest: 5,
							price: 1000,
						},
						type: 0,
					},
				}

				return (window.location.href = "/promocode")
			}
		})
	}

	getPaymentsPromocode = async (id) => {
		axios
			.get(`/promocodes/used/${id}`, {
				params: {
					"User-Token": locals.get("user").token,
					promocodeId: id,
				},
			})
			.then(
				action((response) => {
					const { data, success } = response.data

					this.paymentPromocode.success = success
					if (success) {
						this.paymentPromocode.data = data.map((item) => {
							return { key: item.id, ...item }
						})
					}
				}),
			)
	}

	exportPaymentsPromocode = async (event, id) => {
		event.preventDefault()

		await axios
			.get(`/promocodes/used/${id}`, {
				params: {
					"User-Token": locals.get("user").token,
					promocodeId: id,
				},
			})
			.then(
				action((response) => {
					const { data, success } = response.data
					if (success) {
						this.exportPayments(data)
					}
				}),
			)
	}

	deletePromocode = (id) => {
		axios
			.delete(`/promocodes/delete/${id}`, {
				params: {
					"User-Token": locals.get("user").token,
					promocodeId: id,
				},
			})
			.then((response) => {
				const { success } = response.data
				if (success) {
					this.getListPromocodes()
				}
			})
			.catch((error) => {
				// handle any errors
			})
	}
	// End - API
}

export const store = new Promocode()
