import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import { NavLink } from "react-router-dom"

import { teamStore } from "store/Team/TeamStore"

const TeamList = (props) => {
	const columns = [
		{
			title: "Имя",
			dataIndex: "name",
			render: (text, record) => <NavLink to={`/team/${record.id}`}>{text}</NavLink>,
		},
		{
			title: "Позиция",
			dataIndex: "post",
		},
		{
			title: "Статус",
			dataIndex: "status",
			render(item) {
				let status
				if (Number(item) === 1) {
					status = "Активен"
				} else {
					status = "Не активен"
				}
				return <div>{status}</div>
			},
		},
		{
			title: "Дата создания",
			dataIndex: "date_create",
		},
		{
			title: "Дата изменения",
			dataIndex: "date_update",
		},
		{
			title: "Действия",
			dataIndex: "operation",
			width: "20%",
			render: (_, record) => {
				return (
					<Space>
						<span>
							<NavLink
								to={`/team/${record.id}`}
								style={{
									marginRight: 8,
								}}
							>
								<EditOutlined />
							</NavLink>

							<Popconfirm
								title="Удалить запись?"
								onConfirm={() => teamStore.deleteTeam(record.key)}
								okText="Да"
								cancelText="Нет"
							>
								<Button type="link">
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</span>
					</Space>
				)
			},
		},
	]
	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={teamStore.pagination}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
}

export default observer(TeamList)
