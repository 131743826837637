import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Col, Input, Row, Space } from "antd"
import { observer } from "mobx-react-lite"

import { authStore } from "store/Auth/AuthStore"

const Auth = observer(() => {
	return (
		<Row justify="center" align="middle">
			<Col xs={24} className="auth-form-inner">
				<form onSubmit={(e) => authStore.LogIn(e)}>
					<Space direction="vertical">
						<Input
							size="large"
							value={authStore.user.login}
							onChange={(e) => authStore.onChange("login", e.target.value)}
							placeholder="Введите логин"
							prefix={<UserOutlined />}
							required
						/>
						<Input.Password
							size="large"
							placeholder="Пароль"
							value={authStore.user.password}
							onChange={(e) => authStore.onChange("password", e.target.value)}
							prefix={<LockOutlined />}
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							required
						/>
						<Button size="large" type="primary" htmlType="submit" block>
							Войти
						</Button>
					</Space>
				</form>
			</Col>
		</Row>
	)
})

export default Auth
