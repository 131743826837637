import { ConfigProvider } from "antd"
import ruRU from "antd/lib/locale/ru_RU"
import { BrowserRouter } from "react-router-dom"

import Middleware from "./Middleware"
import "./assets/styles/App.scss"
import RoutesAuth from "./routes/RoutesAuth"
import RoutesPrivate from "./routes/RoutesPrivate"

const App = () => {
	return (
		<ConfigProvider locale={ruRU}>
			<BrowserRouter>{Middleware.isLigged() ? <RoutesPrivate /> : <RoutesAuth />}</BrowserRouter>
		</ConfigProvider>
	)
}

export default App
