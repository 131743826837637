// Import Framework
import { message } from "antd"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
// Import Store
import { action, makeAutoObservable } from "mobx"
import moment from "moment"
import "moment/locale/ru"

// Import Components
import axios from "../../axios"
import config from "../../config.json"

class LessonsStore {
	limit = 999
	listLessons = []
	modalView = false
	idLessons = 0
	lesson = {}
	constructor() {
		makeAutoObservable(this)
	}

	onChange(name, value) {
		this.lesson[name] = value
	}

	onChangeDate(name, value) {
		this.lesson[name] = Math.trunc(moment(value) / 1000)
	}

	openModal = (id = 0) => {
		this.modalView = true
		if (Number(id) === Number(this.idLessons)) {
			this.idLessons = id
			return this.setLesson()
		}
		if (Number(id) !== Number(this.idLessons)) {
			this.idLessons = id
			return this.getLesson(this.idLessons)
		}
	}

	closeModal = (id) => {
		this.modalView = false
		this.idLessons = 0
		this.getLessons(id)
	}

	addLesson = (e, id) => {
		e.preventDefault()
		let lesson = new FormData()
		let url
		if (this.lesson.id > 0) {
			url = `edit/${this.lesson.id}`
		} else {
			url = "new"
		}

		lesson.append("course", id)
		lesson.append("title", this.lesson.title)
		lesson.append("video", this.lesson.video)
		lesson.append("date_active", this.lesson.date_active)
		lesson.append("status", this.lesson.status)
		lesson.append("format", this.lesson.format)
		lesson.append("comments", this.lesson.comments)
		if (this.lesson.text !== "")
			lesson.append("text", draftToHtml(convertToRaw(this.lesson.text.getCurrentContent())))
		if (this.lesson?.images?.length > 0) {
			let images_urls = []
			this.lesson.images.map((file) => {
				return file?.url
					? images_urls.push("/" + file.url.split("//")[2])
					: lesson.append("images[]", file.originFileObj)
			})
			if (images_urls.length > 0) lesson.append("images_urls", JSON.stringify(images_urls))
		}

		axios
			.post(`/lessons/${url}`, lesson)
			.then(
				action((success) => {
					message.success("Лекция успешно добавлена!")
					this.closeModal(id)
				}),
			)
			.catch((error) => {
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	getLessons(coursesId) {
		axios
			.get(`/lessons/lessons/${coursesId}`, {
				limit: this.limit,
			})
			.then(
				action((success) => {
					const lessons = success.data[0]
					this.listLessons = lessons.map((lesson) => {
						return { key: lesson.id, ...lesson }
					})
				}),
			)
	}

	getLesson(id) {
		axios
			.get(`/lessons/lesson/${id}`)
			.then(
				action((success) => {
					let item = success.data
					if (item.images !== null && item.images.length > 0) {
						try {
							let images = JSON.parse(item.images)
							item.images = []
							images.map((image, index) => {
								return item.images.push({
									uid: index,
									status: "done",
									url: config.apiUrl + image,
								})
							})
						} catch (e) {
							item.images = []
						}
					} else {
						item.images = []
					}

					this.lesson = item
					const contentBlock = htmlToDraft(item.text)
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
						const editorState = EditorState.createWithContent(contentState)
						this.lesson.text = editorState
					}
				}),
			)
			.catch((error) => {
				if (error.request.status === 400) {
					window.location.href = "/"
				}
				throw new Error(`Ошибка ${error.status}`)
			})
	}

	setLesson() {
		this.lesson = {
			id: 0,
			title: "",
			video: "",
			date_active: "",
			status: "",
			format: 1,
			comments: 1,
			text: "",
		}
	}

	setDate(d) {
		const dateFormat = "YYYY-MM-DD HH:mm"
		d = moment.unix(d).format(dateFormat)
		return moment(d, dateFormat)
	}

	deleteLesson(id, coursesId) {
		axios
			.delete(`/lessons/lesson/${id}`)
			.then(
				action((success) => {
					this.getLessons(coursesId)
				}),
			)
			.catch((error) => {
				message.error("Ошибка")
			})
	}

	changeEditor(e) {
		this.lesson.text = e
	}
}

export const lessonsStore = new LessonsStore()
