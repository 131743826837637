import React from "react"
import { createRoot } from "react-dom/client"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)

// eslint-disable-next-line no-console
process.env.NODE_ENV === "development" && reportWebVitals(console.debug)
