import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Map, YMaps } from "@pbe/react-yandex-maps"
import { Button, Input, Space } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"

import { usersStore } from "store/Users/UsersStore"

import "./PersonalUser.scss"

const mapState = {
	center: [55.76, 37.64],
	zoom: 10,
	controls: [],
}
const PersonalUser = () => {
	return (
		<>
			<form
				className="PersonalUserForm"
				onSubmit={(e) => {
					usersStore.postUser(e)
				}}
			>
				<Space direction="vertical" className="PersonalUserForm__body">
					<div className="PersonalUserForm__body-group">
						<label>E-mail:</label>
						<Input
							defaultValue={usersStore.newUser.email}
							value={usersStore.newUser.email}
							disabled={true}
							className={usersStore.statusEditUser ? "inputDisabled activeDisabled" : "inputDisabled"}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Телефон:</label>
						<Input
							defaultValue={usersStore.newUser.phone}
							value={usersStore.newUser.phone}
							disabled={true}
							className={usersStore.statusEditUser ? "inputDisabled activeDisabled" : "inputDisabled"}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Имя:</label>
						<Input
							defaultValue={usersStore.newUser.name}
							value={usersStore.newUser.name}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("name", e.target.value)}
							disabled={usersStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Фамилия:</label>
						<Input
							defaultValue={usersStore.newUser.surname}
							value={usersStore.newUser.surname}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("surname", e.target.value)}
							disabled={usersStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Страна, город:</label>
						<input
							id="suggest"
							className="ant-input input-city"
							ref={usersStore.addressInput}
							placeholder={
								usersStore.newUser.country && usersStore.newUser.city
									? `${usersStore.newUser.country}, ${usersStore.newUser.city}`
									: "Введите город"
							}
							disabled={usersStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>VK:</label>
						<Input
							defaultValue={usersStore.newUser.vk}
							value={usersStore.newUser.vk}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("vk", e.target.value)}
							disabled={usersStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Instagram:</label>
						<Input
							defaultValue={usersStore.newUser.instagram}
							value={usersStore.newUser.instagram}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("instagram", e.target.value)}
							disabled={usersStore.statusEditUser}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Пароль</label>
						<Input.Password
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							placeholder="******"
							value={usersStore.newUser.password}
							disabled={usersStore.statusEditUser}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("password", e.target.value)}
						/>
					</div>

					<div className="PersonalUserForm__body-group">
						<label>Подтвердите пароль</label>
						<Input.Password
							placeholder="******"
							iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							value={usersStore.newUser.passwordConfirmation}
							disabled={usersStore.statusEditUser}
							bordered={!usersStore.statusEditUser}
							onChange={(e) => usersStore.onChange("passwordConfirmation", e.target.value)}
						/>
					</div>

					<div className="PersonalUserForm__body-button">
						<Button
							type="primary"
							onClick={() => usersStore.editUser()}
							size="large"
							className={usersStore.statusEditUser ? "toggleEdit--active" : "toggleEdit--default"}
						>
							Редактировать
						</Button>
						<Button
							type="primary"
							size="large"
							onClick={(e) => usersStore.saveUser()}
							htmlType="submit"
							className={!usersStore.statusEditUser ? "toggleEdit--active" : "toggleEdit--default"}
						>
							Сохранить
						</Button>
					</div>
				</Space>
				<div style={{ display: "none" }}>
					<YMaps query={{ load: "package.full" }}>
						<Map
							state={mapState}
							onLoad={usersStore.onYmapsLoad}
							instanceRef={(map) => (usersStore.map = map)}
						/>
					</YMaps>
				</div>
			</form>
		</>
	)
}

export default observer(PersonalUser)
