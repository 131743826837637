import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { observer } from "mobx-react-lite"
import { NavLink } from "react-router-dom"

import { usersStore } from "store/Users/UsersStore"

const EditableTable = (props) => {
	const columns = [
		{
			title: "№",
			dataIndex: "key",
		},
		{
			title: "Имя",
			dataIndex: "name",
			render: (text, record) => <NavLink to={`/users/${record.id}`}>{text}</NavLink>,
		},
		{
			title: "Фамилия",
			dataIndex: "surname",
		},
		{
			title: "Номер телефона",
			dataIndex: "phone",
		},
		{
			title: "E-mail",
			dataIndex: "email",
		},
		{
			title: "Город",
			dataIndex: "city",
		},
		{
			title: "Дата создания",
			dataIndex: "date_reg",
		},
		{
			title: "Дата изменения",
			dataIndex: "date_update",
		},
		{
			title: "Действия",
			dataIndex: "operation",
			render: (_, record) => {
				return (
					<Space>
						<span>
							<NavLink
								to={`/users/${record.id}`}
								style={{
									marginRight: 8,
								}}
							>
								<EditOutlined />
							</NavLink>

							<Popconfirm
								title="Удалить запись?"
								onConfirm={() => usersStore.UserDelete(record.id, props.type)}
								okText="Да"
								cancelText="Нет"
							>
								<Button type="link">
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</span>
					</Space>
				)
			},
		},
	]

	return (
		<>
			<Table
				dataSource={props.dataSource}
				columns={columns}
				pagination={props.admin ? usersStore.paginationAdmins : usersStore.paginationUsers}
				showSizeChanger={false}
			/>
			<div className="tableAllItems">Всего записей: {props.items}</div>
		</>
	)
}

export default observer(EditableTable)
