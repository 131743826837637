import SEO from "@americanexpress/react-seo"

import AuthForm from "components/Auth/AuthForm"

import "./AuthPage.scss"

import logo from "../../assets/images/logo.png"

const AuthPage = () => {
	return (
		<>
			<SEO title="Страница авторизации" />
			<div className="authPage">
				<div className="authPage__img">
					<img src={logo} alt="Modnaya Kontora" />
				</div>
				<AuthForm />
			</div>
		</>
	)
}

export default AuthPage
