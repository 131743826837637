import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

import HeaderComponent from "components/Header/Header"
import PaymentsList from "components/Payments/PaymentsList"
import TitlePage from "components/TitlePage/TitlePage"

import { paymentStore } from "store/PaymentStore/PaymentStore"

import "./PaymentPage.scss"

const UpdatePaymentReceipt = () => {
	return null
}

const PaymentPage = () => {
	useEffect(() => {
		paymentStore.getListPayments()
	}, [])

	return (
		<>
			<SEO title="Список оплат" />
			<HeaderComponent />
			<div className="container">
				<TitlePage
					title="Список оплат"
					fileName="payments"
					store={paymentStore}
					isAviableUpdatePaymentReceipt={true}
				/>
				<PaymentsList
					dataSource={paymentStore.payments.data}
					courses={paymentStore.payments.courses}
					items={paymentStore.payments.count}
					pagination={paymentStore.paginationPayments}
				/>
			</div>
		</>
	)
}

export default observer(PaymentPage)
